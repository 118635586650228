import React from "react";
import Navbar from "../../components/Landing/NavBar";
import Footer from "../../components/Landing/Footer";
import PythonP from "../../components/PythonProPage/PythonPro";
import PythonP2 from "../../components/PythonProPage/PythonPro2";
import PythonP3 from "../../components/PythonProPage/PythonPro3";
import Company from "../../components/Pricing/Company";
import TrialLessonPopup from "../../components/FreeLessonPopup/FreeLesson"; // Popup componentini import ediyoruz
import SlidingBanner from "../../components/SlidingBanner/SlidingBanner"; // Banner'ı import edin

export default function Pricingpage() {
  return (
    <>
      <SlidingBanner /> {/* Banner'ı en üste ekleyin */}
      <Navbar />
      <PythonP />
      <PythonP2 />
      <PythonP3 />
      <Company />
      <Footer />
      <TrialLessonPopup /> {/* Popup componentini ekliyoruz */}
    </>
  );
}
