import React, { useEffect, useState } from "react";
import './Week1.css'; // CSS dosyasını paylaşmak

const Week3 = () => {
  const [content, setContent] = useState(null);
  const [answers, setAnswers] = useState({});
  const [submittedQuizzes, setSubmittedQuizzes] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    fetch('https://backend.ubicraft.org/api/weeks/3')
      .then(response => response.json())
      .then(data => {
        if (data && data.length > 0) {
          const sortedLessons = data[0].lessons.sort((a, b) => a.lesson_id - b.lesson_id);
          const sortedData = {
            ...data[0],
            lessons: sortedLessons
          };
          setContent(sortedData);
        }
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  const handleOptionChange = (quizId, index) => {
    setAnswers(prevAnswers => ({
      ...prevAnswers,
      [quizId]: index
    }));
  };

  const handleSubmit = async () => {
    setIsSubmitted(true);
    for (const [quizId, answer] of Object.entries(answers)) {
      const quiz = content.lessons.flatMap(lesson => lesson.quizzes).find(q => q.quiz_id === parseInt(quizId));
      const isCorrect = answer === quiz.correct_answer;

      const response = await fetch('https://backend.ubicraft.org/api/answers', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user_id: 1, quiz_id: parseInt(quizId), answer, is_correct: isCorrect }),
      });

      const result = await response.json();
      if (response.status === 400) {
        alert(result.message);
      } else {
        setSubmittedQuizzes(prev => ({ ...prev, [quizId]: true }));
      }
    }
  };

  const formatHtmlContent = (htmlContent) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlContent;
    
    // Add classes to h3 and pre elements
    tempDiv.querySelectorAll('h3').forEach(el => el.classList.add('title'));
    tempDiv.querySelectorAll('pre').forEach(el => el.classList.add('code'));
    
    return tempDiv.innerHTML;
  };

  if (!content) {
    return <div>Loading...</div>;
  }

  const allQuizzes = content.lessons.flatMap(lesson => lesson.quizzes);

  return (
    <div className="week-container">
      <h2 className="week-title">{content.week_title}</h2>
      {content.lessons.map((lesson, index) => (
        <div key={index} className="lesson">
          <h3 className="title">{lesson.title}</h3>
          <div className="lesson-content" dangerouslySetInnerHTML={{ __html: formatHtmlContent(lesson.content) }} />
        </div>
      ))}
      <div className="quiz-section">
        <h3>Tüm Quizler</h3>
        {allQuizzes.map((quiz, idx) => (
          <div key={idx} className="quiz">
            <h4>{quiz.question}</h4>
            <div className="options">
              {quiz.options.map((option, i) => (
                <div key={i} className="option">
                  <input
                    type="radio"
                    id={`option-${quiz.quiz_id}-${i}`}
                    name={`option-${quiz.quiz_id}`}
                    value={i}
                    onChange={() => handleOptionChange(quiz.quiz_id, i)}
                    disabled={submittedQuizzes[quiz.quiz_id]}
                  />
                  <label htmlFor={`option-${quiz.quiz_id}-${i}`}>{option}</label>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      {!isSubmitted ? (
        <button className="submit-button" onClick={handleSubmit} disabled={Object.keys(answers).length !== allQuizzes.length}>
          Gönder
        </button>
      ) : (
        <div className="result">Cevaplar kaydedildi!</div>
      )}
    </div>
  );
};

export default Week3;
