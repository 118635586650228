import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import "./StudentScores.css";

const StudentScores = () => {
  const { userId } = useParams();
  const [scores, setScores] = useState([]);
  const [loading, setLoading] = useState(true);

  const scoreRanges = [
    { min: 0, max: 40, color: '#ff4d4f', label: 'Çok Düşük' },
    { min: 41, max: 80, color: '#fa8c16', label: 'Düşük' },
    { min: 81, max: 120, color: '#faad14', label: 'Orta' },
    { min: 121, max: 160, color: '#52c41a', label: 'İyi' },
    { min: 161, max: 200, color: '#1890ff', label: 'Mükemmel' },
  ];

  useEffect(() => {
    fetchScores();
  }, []);

  const fetchScores = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`https://backend.ubicraft.org/api/student/${userId}/scores`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (response.ok) {
        const data = await response.json();
        setScores(data);
      } else {
        console.error('Failed to fetch scores:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching scores:', error);
    } finally {
      setLoading(false);
    }
  };

  const calculatePercentage = (score) => {
    return (score / 200) * 100;
  };

  const getProgressColor = (score) => {
    const range = scoreRanges.find(range => score >= range.min && score <= range.max);
    return range ? range.color : '#1890ff';
  };

  return (
    <div className="scores-container">
      <h2 className="scores-title">Haftalık Puan Tablosu</h2>
      {loading ? (
        <div className="loading-skeleton">
          {[...Array(5)].map((_, index) => (
            <div key={index} className="skeleton-item"></div>
          ))}
        </div>
      ) : (
        <>
          <div className="scores-list">
            {scores.map((score, index) => (
              <div key={index} className="score-item">
                <span className="week-label">Hafta {score.week}</span>
                <div 
                  className="progress-circle" 
                  style={{
                    background: `conic-gradient(
                      ${getProgressColor(score.score)} ${calculatePercentage(score.score)}%, 
                      #e0e0e0 ${calculatePercentage(score.score)}%
                    )`
                  }}
                >
                  <span className="progress-circle-value">{score.score}</span>
                </div>
              </div>
            ))}
          </div>
          <div className="legend">
            <h3 className="legend-title">Puan Aralıkları</h3>
            {scoreRanges.map((range, index) => (
              <div key={index} className="legend-item">
                <div className="legend-color" style={{ backgroundColor: range.color }}></div>
                <span className="legend-label">{range.label} ({range.min}-{range.max})</span>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default StudentScores;