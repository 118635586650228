import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';

// ngrok URL'si ile güncellenmiş API_BASE_URL
const API_BASE_URL = 'https://fc0b-176-41-33-31.ngrok-free.app '; // ngrok URL'sini içerir

const OrderStatus = () => {
  const { orderId } = useParams();
  const location = useLocation();
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchPaymentDetails = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/payment-details/${orderId}`); // ngrok URL kullanıyoruz
        const data = await response.json();
        if (response.ok) {
          setPaymentDetails(data);
        } else {
          setError(data.error);
        }
      } catch (err) {
        setError('Ödeme detayları alınırken bir hata oluştu.');
      }
    };

    fetchPaymentDetails();
  }, [orderId]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const status = queryParams.get('status');
    if (status === 'failed') {
      setError('Ödeme işlemi başarısız.');
    }
  }, [location]);

  if (error) {
    return (
      <div className="order-status">
        <h1>Ödeme Durumu</h1>
        <p className="error">{error}</p>
      </div>
    );
  }

  if (!paymentDetails) {
    return (
      <div className="order-status">
        <h1>Ödeme Durumu</h1>
        <p>Yükleniyor...</p>
      </div>
    );
  }

  return (
    <div className="order-status">
      <h1>Ödeme Durumu</h1>
      {paymentDetails.status === 'success' ? (
        <div>
          <p>Ödemeniz başarılı bir şekilde tamamlandı!</p>
          <p>Sipariş ID: {paymentDetails.order_id}</p>
          <p>Tutar: ₺{paymentDetails.final_amount}</p>
          {/* Diğer detayları ekleyebilirsiniz */}
        </div>
      ) : (
        <div>
          <p>Ödeme işlemi başarısız oldu.</p>
          <p>Hata Mesajı: {paymentDetails.status === 'failed' ? 'Ödeme başarısız.' : ''}</p>
        </div>
      )}
    </div>
  );
};

export default OrderStatus;
