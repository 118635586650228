// DistanceSalesAgreementPopup.js

import React from 'react';
import './DistanceSalesAgreementPopup.css';

const DistanceSalesAgreementPopup = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="distance-popup-overlay">
      <div className="distance-popup-content">
        <div className="distance-popup-header">
          <h2>MESAFELİ SATIŞ SÖZLEŞMESİ</h2>
          <button className="distance-close-button" onClick={onClose}>
            X
          </button>
        </div>
        <div className="distance-popup-body">
          <h3>1. TARAFLAR</h3>
          <p><strong>Satıcı:</strong><br />
          Satıcı: Yasin Doğukan Kılıç<br />
          Adres: Bülbüzade Mah. 136019. Sok. No:20 İç Kapı No:17 Şahinbey/Gaziantep<br />
          E-posta: info@ubicraft.com<br />
          Telefon: +90 (505) 713 17 27</p>

          <h3>2. SÖZLEŞMENİN KONUSU</h3>
          <p>Bu sözleşme, Satıcı'nın sunduğu eğitim hizmetlerinin Alıcı tarafından satın alınması ve kullanım şartlarını düzenlemektedir.</p>

          <h3>3. ÜRÜN/HİZMET TANIMI</h3>
          <p>Satıcı, Alıcı'ya aşağıda belirtilen eğitim hizmetlerini sunmayı taahhüt eder:</p>
          <ul>
            <li><strong>Kurs Adı:</strong> Python Plus / Python Pro / Python Start</li>
            <li><strong>Kurs Süresi:</strong> Belirtilen hafta sayısı</li>
            <li><strong>İçerik:</strong> Ders materyalleri, gerçek dünya projeleri, sertifikalar vb.</li>
          </ul>

          <h3>4. SATIŞ VE TESLİMAT ŞARTLARI</h3>
          <ul>
            <li><strong>Satışın Gerçekleşmesi:</strong> Alıcı'nın ödeme işlemini tamamlamasıyla satış gerçekleşir.</li>
            <li><strong>Teslimat:</strong> Eğitim materyalleri ve erişim bilgileri, ödeme onaylandıktan sonra e-posta ile gönderilecektir.</li>
          </ul>

          <h3>5. FİYAT VE ÖDEME ŞARTLARI</h3>
          <ul>
            <li><strong>Kurs Ücretleri:</strong>
              <ul>
                <li>Python Start: 14.400 TL</li>
                <li>Python Plus: 10.080 TL</li>
                <li>Python Plus: 1600 TL</li>
              </ul>
            </li>
            <li><strong>Ödeme Yöntemleri:</strong> Kredi kartı, banka havalesi vb.</li>
            <li><strong>İndirimler:</strong> Kampanya ve indirim kodları ile değişiklik gösterebilir.</li>
          </ul>

          <h3>6. İADE KOŞULLARI</h3>
          <ul>
            <li><strong>İade Süresi:</strong> Kursun ilk 1 ayında koşulsuz şartsız iade.</li>
            <li><strong>İade İşlemleri:</strong> Alıcı, iade talebini info@ubicraft.com adresine e-posta göndererek veya müşteri temsilcileri ile iletişime geçerek gerçekleştirebilir.</li>
            <li><strong>İade İşleminin İşlenmesi:</strong> İade talepleri alındıktan sonra 3 iş günü içinde işlenir. Tutarın hesabınıza geçmesi banka işlemlerine bağlı olarak değişebilir.</li>
          </ul>

          <h3>7. KULLANICI YÜKÜMLÜLÜKLERİ</h3>
          <ul>
            <li>Alıcı, platformu yalnızca kişisel eğitim amaçları için kullanacaktır.</li>
            <li>Platform içeriğini izinsiz olarak kopyalamayacak, satmayacak veya ticari amaçla kullanmayacaktır.</li>
            <li>Diğer kullanıcıların hesaplarına izinsiz erişim sağlamayacaktır.</li>
          </ul>

          <h3>8. KÖTÜYE KULLANIM VE YAPTIRIMLAR</h3>
          <ul>
            <li>Alıcı, platformun herhangi bir özelliğini kötüye kullanamaz.</li>
            <li>Kötüye kullanım durumunda, Alıcı'nın hesabı askıya alınabilir veya tamamen kapatılabilir. Bu durumda, herhangi bir geri ödeme yapılmayacaktır.</li>
          </ul>

          <h3>9. HİZMET SÜREKLİLİĞİ</h3>
          <ul>
            <li>Hizmetler, resmi tatiller ve dini bayramlar dışında kesintisiz olarak devam edecektir.</li>
            <li>Öğretmen derse katılamazsa, yerine başka bir öğretmen atanır veya ders başka bir tarihe ertelenebilir.</li>
          </ul>

          <h3>10. SORUMLULUK REDDİ</h3>
          <p>Satıcı, sağlanan hizmetlerin kesintisiz, hatasız veya tamamen güvenli olduğuna dair garanti vermez. Platformun kullanımı sırasında doğabilecek zararlardan sorumlu tutulamaz.</p>

          <h3>11. DEĞİŞİKLİKLER</h3>
          <p>Satıcı, bu sözleşmeyi herhangi bir zamanda değiştirme hakkını saklı tutar. Değişiklikler, kullanıcıya platform üzerinden duyurulacak ve kullanıcıların bu değişiklikleri kabul etmesi beklenecektir.</p>

          <h3>12. YASAL UYUŞMAZLIKLAR</h3>
          <p>Bu sözleşme, Türkiye Cumhuriyeti yasalarına tabidir. Uyuşmazlık durumunda Gaziantep mahkemeleri yetkili olacaktır.</p>

          <h3>13. İLETİŞİM BİLGİLERİ</h3>
          <p>
            <strong>Adres:</strong> Bülbüzade Mah. 136019. Sok. No:20 İç Kapı No:17 Şahinbey/Gaziantep<br />
            <strong>E-posta:</strong> info@ubicraft.com<br />
            <strong>Telefon:</strong> +90 (505) 713 17 27
          </p>
        </div>
      </div>
    </div>
  );
};

export default DistanceSalesAgreementPopup;
