import React, { useState, useEffect } from "react";
import { IoGrid } from "react-icons/io5";
import { GiGraduateCap } from "react-icons/gi";
import { BsArchive, BsListTask } from "react-icons/bs";
import { FaRegBell } from "react-icons/fa6";
import { FiSettings } from "react-icons/fi";
import { CiLogout } from "react-icons/ci";
import { AiOutlineClose } from "react-icons/ai";
import { Link, useParams } from "react-router-dom";
import "./Sidebar.css"; 

export default function Sidebar({ show, setter }) {
  const { userId } = useParams();
  const [selected, setSelected] = useState(0);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  useEffect(() => {
    setIsSidebarOpen(show);
  }, [show]);

  const token = localStorage.getItem('token');
  const user = token ? parseJwt(token) : null;

  const teacherMenuList = [
    { name: "Takvim", route: `/dashboard/${userId}/teacher`, icon: <IoGrid /> },
    { name: "Sınıflar", route: `/dashboard/${userId}/classes`, icon: <BsArchive /> },
    { name: "Dersler", route: `/dashboard/${userId}/notification`, icon: <FaRegBell /> },
    { name: "Çıkış", route: "/", icon: <CiLogout /> },
  ];

  const studentMenuList = [
    { name: "Ana Sayfa", route: `/student/${userId}`, icon: <IoGrid /> },
    { name: "Dersler", route: `/student/${userId}/notification`, icon: <FaRegBell /> },
    { name: "Ayarlar", route: `/student/${userId}/settings`, icon: <FiSettings /> },
    { name: "Çıkış Yap", route: "/", icon: <CiLogout /> },
  ];

  const MenuList = user && user.role === 'teacher' ? teacherMenuList : studentMenuList;

  const className = "sidebar bg-dark-blue transition-margin ease-in-out duration-500 relative md-static top-0 bottom-0 left-0 z-40 overflow-y-auto";
  const appendClass = isSidebarOpen ? " ml-0" : " ml-negative";

  const MenuItem = ({ icon, name, route, index }) => {
    return (
      <Link
        key={index}
        onClick={() => {
          setSelected(index);
          setIsSidebarOpen(false);
        }}
        to={route}
        className={`menu-item flex gap-small text-md mt-4 py-2 px-4 rounded-lg ${
          selected === index ? "bg-selected" : "bg-dark-blue"
        } cursor-pointer`}
      >
        <div className={`menu-icon text-xl flex justify-center width-25px ${selected === index ? "text-white" : "text-light-gray"}`}>
          {icon}
        </div>
        <div className={`menu-text ${selected === index ? "text-white" : "text-light-gray"} text-lg font-poppins font-normal ml-3`}>
          {name}
        </div>
      </Link>
    );
  };

  const ModalOverlay = () => (
    <div
      className={`flex md-hidden fixed top-0 right-0 bottom-0 left-0 bg-overlay z-30`}
      onClick={() => {
        setIsSidebarOpen(false);
        setter(false);
      }}
    />
  );

  return (
    <>
      <div className={`${className}${appendClass} p-10`}>
        <div className="flex justify-end md:hidden">
          <AiOutlineClose
            className="text-light-gray text-2xl cursor-pointer"
            onClick={() => {
              setIsSidebarOpen(false);
              setter(false);
            }}
          />
        </div>
        <div className="flex flex-col mb-1 py-2 items-center">
          <img
            src={"/assets/user.svg"}
            alt="User Avatar"
            width={80}
            height={80}
            className="rounded-full mb-2"
          />
          <h3 className="text-center text-base font-semibold font-poppins text-white">
            {user ? user.name : "User"}
          </h3>
          <h3 className="text-center text-base font-normal font-poppins text-light-gray">
            {user ? (user.role === 'teacher' ? "Teacher" : "Student") : "Role"}
          </h3>
        </div>
        <div className="flex flex-col">
          {MenuList.map((item, index) => (
            <MenuItem
              key={index}
              index={index}
              icon={item.icon}
              name={item.name}
              route={item.route}
            />
          ))}
        </div>
      </div>
      {isSidebarOpen ? <ModalOverlay /> : null}
    </>
  );
}

function parseJwt(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}
