import React, { useState, useEffect } from 'react';
import { ChevronDown, ChevronUp, ChevronRight, BookOpen, Layout, Menu } from 'lucide-react';
import axios from 'axios';
import './activities.css';

const Sidebar = ({ isOpen, onToggle, onActivitySelect }) => {
  const [weeklyActivities, setWeeklyActivities] = useState([]);
  const [expandedWeeks, setExpandedWeeks] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchActivities = async () => {
      try {
        const response = await axios.get('https://backend.ubicraft.org/api/courses/1/weekly-activities');
        setWeeklyActivities(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Aktiviteler yüklenirken hata:', error);
        setLoading(false);
      }
    };

    fetchActivities();
  }, []);

  const toggleWeek = (weekNumber) => {
    setExpandedWeeks(prev => ({
      ...prev,
      [weekNumber]: !prev[weekNumber]
    }));
  };

  const handleActivityClick = (activity) => {
    if (onActivitySelect) {
      onActivitySelect(activity);
    }
  };

  if (loading) {
    return (
      <div className={`ubiAI-sidebar ${!isOpen ? 'ubiAI-collapsed' : ''}`}>
        <div className="ubiAI-loading">Yükleniyor...</div>
      </div>
    );
  }

  return (
    <>
      <button className="ubiAI-sidebar-toggle" onClick={onToggle}>
        <Menu size={20} />
      </button>
      
      <div className={`ubiAI-sidebar ${!isOpen ? 'ubiAI-collapsed' : ''}`}>
        <div className="ubiAI-sidebar-header">
          <h2><BookOpen size={20} /> Dersler ve Aktiviteler</h2>
        </div>

        <div className="ubiAI-sidebar-content">
          {weeklyActivities.map((week) => (
            <div key={week.week_number} className="ubiAI-week-container">
              <button
                className="ubiAI-week-header"
                onClick={() => toggleWeek(week.week_number)}
              >
                <div className="ubiAI-week-title">
                  <span className="ubiAI-week-number">{week.week_number}</span>
                  <span className="ubiAI-week-name">{week.lesson_name}</span>
                </div>
                {expandedWeeks[week.week_number] ? (
                  <ChevronUp size={16} />
                ) : (
                  <ChevronDown size={16} />
                )}
              </button>

              {expandedWeeks[week.week_number] && (
                <div className="ubiAI-activities-list">
                  {week.activities.map((activity) => (
                    <div key={activity.activity_id} className="ubiAI-activity-item">
                      <div className="ubiAI-activity-content">
                        <Layout size={14} />
                        <span>{activity.activity_name}</span>
                      </div>
                      <button 
                        className="ubiAI-activity-button"
                        onClick={() => handleActivityClick(activity)}
                      >
                        <ChevronRight size={16} />
                      </button>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Sidebar;
