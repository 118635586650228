import React, { useEffect } from "react";
import { useLocation } from 'react-router-dom';
import Navbar from "../../components/Landing/NavBar";
import HeroSection from "../../components/Landing/HeroSection";
import FeatureSection from "../../components/Landing/FeatureSection";
import PythonIDESection from "../../components/Landing/IDEIntro";
import EducationPlatformComponent from "../../components/Landing/EducationPlattform";
import Footer from "../../components/Landing/Footer";
import CoursesSection from "../../components/Landing/CoursesSection";
import Experience from "../../components/Landing/Experience";
import Creativity from "../../components/Landing/Creativity";
import Company from "../../components/Pricing/Company";
import SlidingBanner from "../../components/SlidingBanner/SlidingBanner";
import './Landing.css'; // CSS dosyasını import edin

const Landing = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash === '#registration') {
      const registrationElement = document.getElementById('registration');
      if (registrationElement) {
        registrationElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location.hash]);

  return (
    <div className="ubicraft-landing-wrapper">
      <div className="ubicraft-landing-container">
        <SlidingBanner />
        <Navbar />
        <main className="ubicraft-landing-main">
          <HeroSection />
          <Creativity />
          <CoursesSection />
          <Experience />
          <FeatureSection />
          <PythonIDESection />
          <EducationPlatformComponent />
          <div id="registration" className="ubicraft-landing-registration">
            <Company />
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default Landing;