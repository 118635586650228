import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './PaymentForm.css';
import whiteLogo from './White.png';

const PaymentForm = () => {
  const [cardNumber, setCardNumber] = useState('');
  const [expireMonth, setExpireMonth] = useState('');
  const [expireYear, setExpireYear] = useState('');
  const [cvv, setCvv] = useState('');
  const [cardHolderName, setCardHolderName] = useState('');
  const [courseName, setCourseName] = useState('');
  const [buyerFirstName, setBuyerFirstName] = useState('');
  const [buyerLastName, setBuyerLastName] = useState('');
  const [buyerPhone, setBuyerPhone] = useState('');
  const [buyerEmail, setBuyerEmail] = useState('');
  const [amount, setAmount] = useState('');
  const [discountCode, setDiscountCode] = useState('');
  const [discountAmount, setDiscountAmount] = useState(0);
  const [installmentOptions, setInstallmentOptions] = useState([]);
  const [selectedInstallment, setSelectedInstallment] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [message, setMessage] = useState('');
  const [isDiscountVisible, setIsDiscountVisible] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [showTerms, setShowTerms] = useState(false);
  const [showPreInfo, setShowPreInfo] = useState(false);

  const navigate = useNavigate();
  const fetchClientIP = async () => {
    try {
      const response = await fetch('https://api.ipify.org?format=json');
      const data = await response.json();
      return data.ip; 
    } catch (error) {
      console.error('IP alınırken hata oluştu:', error);
      return '127.0.0.1';
    }
  };

  // Kurs seçildiğinde kurs fiyatını çekme
const handleCourseSelection = async (e) => {
  const selectedCourse = e.target.value;
  setCourseName(selectedCourse);

  if (selectedCourse) {
    try {
      const response = await fetch('https://backend.ubicraft.org/api/get-course-price', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ courseName: selectedCourse }),
      });

      const result = await response.json();
      if (result.course_price) {
        setAmount(result.course_price);

        // Eğer indirim uygulanacaksa, indirim tutarını hesaplayabilirsiniz
        // Örneğin:
        // const discountAmount = calculateDiscount(result.course_price);
        // setDiscountAmount(discountAmount);
      } else {
        setErrorMessage('Kurs fiyatı bulunamadı.');
      }
    } catch (error) {
      setErrorMessage('Fiyat bilgisi alınırken bir hata oluştu.');
    }
  } else {
    // Kurs seçimi kaldırıldığında fiyatı sıfırlayabilirsiniz
    setAmount(0);
    setDiscountAmount(0); // Eğer indirim tutarı kullanılıyorsa
  }
};


  // previousCardNumberLength değişkenini useRef ile tanımlıyoruz
  const hasSentRequest = useRef(false); // İstek yapılıp yapılmadığını izlemek için useRef kullanıyoruz.

  // Kart numarası girildiğinde taksit seçeneklerini al
  useEffect(() => {
    // Kart numarası 8 haneye ulaştığında ve daha önce istek yapılmamışsa istek yapılır
    if (cardNumber.replace(/\s/g, '').length >= 8 && !hasSentRequest.current) {
      handleCardBin(cardNumber.replace(/\s/g, ''));
      hasSentRequest.current = true; // İstek yapıldığında bunu işaretliyoruz
    }

    // Eğer kart numarası 8 haneden küçükse istek gönderme bayrağını sıfırlarız
    if (cardNumber.replace(/\s/g, '').length < 8) {
      setInstallmentOptions([]); // Taksit seçeneklerini temizliyoruz
      hasSentRequest.current = false; // Yeniden istek yapılabilir hale getiriyoruz
    }
  }, [cardNumber]);

  // Kart Bin Numarasına Göre Taksit Seçeneklerini Çekme
  const handleCardBin = async (cardNumber) => {
    const cardBin = cardNumber.substring(0, 8); // İlk 8 haneyi alıyoruz
    try {
      const response = await fetch('https://backend.ubicraft.org/api/installment-options', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          orderId: `ORDER_${new Date().getTime()}`,
          amount, // Örnek amount, bunu gerektiği şekilde ayarlayın
          currency: 'TRY',
          cardBin,
        }),
      });

      const result = await response.json();
      if (result.success) {
        setInstallmentOptions(result.installmentOptions); // Taksit seçeneklerini güncelle
      } else {
        setErrorMessage('Taksit seçenekleri bulunamadı.');
      }
    } catch (error) {
      setErrorMessage('Taksit seçenekleri alınırken bir hata oluştu.');
    }
  };

  const handlePaymentSubmit = async (e) => {
    e.preventDefault();
    setIsProcessing(true);
  
    const clientIP = await fetchClientIP(); // Müşteri IP'si dinamik olarak alınır
  
    // Eğer bir taksit seçildiyse finalAmount'ı seçilen taksitten al, yoksa normal tutarı kullan
    const finalAmount = selectedInstallment
      ? selectedInstallment.finalAmount
      : parseFloat(amount) - parseFloat(discountAmount || 0);
  
    // Tutarları kuruş cinsinden tam sayıya dönüştür
    const formattedAmount = Math.round((parseFloat(amount) - parseFloat(discountAmount || 0)) * 100); // Kuruş cinsinden
    const formattedFinalAmount = Math.round(finalAmount * 100); // Kuruş cinsinden
  
    // Seçilen taksit seçeneği olup olmadığını kontrol et
    const installmentCount = selectedInstallment ? selectedInstallment.installment : 1; // Tek Çekim için 1
    const installmentAmount = selectedInstallment
      ? Math.round(selectedInstallment.installmentAmount * 100)
      : formattedFinalAmount;
  
    // Order ID oluşturma
    const orderId = `ORDER_${new Date().getTime()}`;
  
    // Ödeme verilerini oluşturma
    const paymentData = {
      orderId: orderId, // Sipariş ID
      amount: formattedAmount, // Kuruş cinsinden tutar
      finalAmount: formattedFinalAmount, // Kuruş cinsinden nihai tutar
      currency: 'TRY',
      cardNumber: cardNumber.replace(/\s/g, ''), // Boşlukları kaldırıyoruz
      cardHolderName,
      expireYear,
      expireMonth,
      cvv,
      callbackUrl: `https://backend.ubicraft.org/api/callback`, // ngrok URL'sini kullanıyoruz
      clientIP: clientIP,
      is3DModel: true, // 3D Secure işaretleyicisi
      installment: installmentCount, // Taksit sayısı
      installmentAmount: installmentAmount, // Kuruş cinsinden taksit başına ödeme tutarı
      courseName: courseName, // courseName'i ekliyoruz
    };
  
    try {
      const response = await fetch('https://backend.ubicraft.org/api/pay-3dsecure', { // ngrok URL'sini kullanıyoruz
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(paymentData),
      });
  
      const result = await response.json();
      setIsProcessing(false);
  
      if (result.succeeded) {
        // 3D Secure doğrulama sayfasını kullanıcının tarayıcısında göster
        document.open();
        document.write(result.threeDsPageContent);
        document.close();
      } else {
        console.error('Ödeme işlemi başarısız:', result);
        setErrorMessage('Ödeme işlemi başarısız: ' + (result.error || 'Bilinmeyen bir hata oluştu.'));
      }
    } catch (error) {
      console.error('Sunucuyla bağlantı kurulamadı:', error);
      setIsProcessing(false);
      setErrorMessage('Sunucuyla bağlantı kurulamadı.');
    }
  };
  

  
  // Adres Bilgileri için state ve fonksiyonlar
  const [buyerCity, setBuyerCity] = useState('');
  const [buyerDistrict, setBuyerDistrict] = useState('');
  const [buyerNeighborhood, setBuyerNeighborhood] = useState('');
  const [buyerAddressDetails, setBuyerAddressDetails] = useState('');

  // İl ve İlçe seçenekleri için dummy data (gerçek uygulamada API'den çekilmelidir)
  const [cityOptions, setCityOptions] = useState([
    { value: 'Istanbul', label: 'İstanbul' },
    { value: 'Ankara', label: 'Ankara' },
    // Diğer iller...
  ]);

  const [districtOptions, setDistrictOptions] = useState([]);
  const [neighborhoodOptions, setNeighborhoodOptions] = useState([]);

  // İl seçimi yapıldığında ilçeleri yükleme
  const handleCitySelection = async (e) => {
    const selectedCity = e.target.value;
    setBuyerCity(selectedCity);

    // İlçeleri API'den çekme (örnek olarak dummy data kullanıldı)
    if (selectedCity) {
      // Burada gerçek bir API çağrısı yapılmalıdır
      const districts = [
        { value: 'Kadikoy', label: 'Kadıköy' },
        { value: 'Besiktas', label: 'Beşiktaş' },
        // Seçilen ile göre ilçeler...
      ];
      setDistrictOptions(districts);
      setBuyerDistrict('');
      setBuyerNeighborhood('');
      setNeighborhoodOptions([]);
    }
  };

  // İlçe seçimi yapıldığında mahalleleri yükleme
  const handleDistrictSelection = async (e) => {
    const selectedDistrict = e.target.value;
    setBuyerDistrict(selectedDistrict);

    // Mahalleleri API'den çekme (örnek olarak dummy data kullanıldı)
    if (selectedDistrict) {
      // Burada gerçek bir API çağrısı yapılmalıdır
      const neighborhoods = [
        { value: 'Moda', label: 'Moda' },
        { value: 'Fenerbahce', label: 'Fenerbahçe' },
        // Seçilen ilçeye göre mahalleler...
      ];
      setNeighborhoodOptions(neighborhoods);
      setBuyerNeighborhood('');
    }
  };

  // Kart numarası formatlama (4 haneli boşluklarla)
  const handleCardNumberChange = (e) => {
    const input = e.target.value.replace(/\D/g, '').substring(0, 16); // Yalnızca rakamlar ve 16 hanelik sınır
    const formattedCardNumber = input
      .match(/.{1,4}/g)
      ?.join(' ')
      .trim() || '';
    setCardNumber(formattedCardNumber);
  };

  // Son kullanma tarihi ay ve yıl seçimi
  const months = Array.from({ length: 12 }, (_, i) => ('0' + (i + 1)).slice(-2));
  const years = Array.from({ length: 12 }, (_, i) => String(new Date().getFullYear() + i));

  // CVV 3 haneli sınır
  const handleCvvChange = (e) => {
    const input = e.target.value.replace(/\D/g, '').substring(0, 3);
    setCvv(input);
  };

  return (
    <div className="container">
      <div className="left-section">
        <img src={whiteLogo} alt="Ubicraft Logo" className="logo" />
        <h1>Ödemelerinizi Kolayca Yapın</h1>
        <p>Herhangi bir kurs ödemenizi bu ekrandan kolayca yapabilirsiniz.</p>
        <ul>
          <li>Lütfen Ubicraft Destek ile görüşme gerçekleştirmeden ödeme yapmayınız.</li>
          <li>Ödeme bilgileriniz kaydedilmeyecektir.</li>
          <li>Lütfen adına ödeme yapılacak öğrenci ismini doğru giriniz.</li>
          <li>Ödeme yapıldıktan kısa bir süre içinde sizinle iletişime geçilecektir.</li>
        </ul>
        <a href="https://wa.me/905057131727" className="support-btn">
          Yardıma mı ihtiyacınız var?
        </a>
        <button className="faq-btn" onClick={() => navigate('/faqs')}>
          Sıkça Sorulan Sorular
        </button>
      </div>

      <div className="right-section">
        <div className="payment-form">
          <h2>Ödemenizi Tamamlayın</h2>
          <form onSubmit={handlePaymentSubmit}>
            <div className="form-group">
              <label>Kurs Seçimi *</label>
              <select value={courseName} onChange={handleCourseSelection} required>
                <option value="">Kurs Seçiniz</option>
                <option value="Python Start">Python Start</option>
                <option value="Python +">Python +</option>
                <option value="Python Pro">Python Pro</option>
                <option value="Deneme">Deneme</option>
              </select>
            </div>
            <div className="form-group">
              <label>Alıcı Adı *</label>
              <input
                type="text"
                value={buyerFirstName}
                onChange={(e) => setBuyerFirstName(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label>Alıcı Soyadı *</label>
              <input
                type="text"
                value={buyerLastName}
                onChange={(e) => setBuyerLastName(e.target.value)}
                required
              />
            </div>

            {/* Adres Bilgileri */}
            <div className="form-group">
              <label>İl *</label>
              <select value={buyerCity} onChange={handleCitySelection} required>
                <option value="">İl Seçiniz</option>
                {cityOptions.map((city) => (
                  <option key={city.value} value={city.value}>
                    {city.label}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-group">
              <label>İlçe *</label>
              <select value={buyerDistrict} onChange={handleDistrictSelection} required>
                <option value="">İlçe Seçiniz</option>
                {districtOptions.map((district) => (
                  <option key={district.value} value={district.value}>
                    {district.label}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-group">
              <label>Mahalle *</label>
              <select
                value={buyerNeighborhood}
                onChange={(e) => setBuyerNeighborhood(e.target.value)}
                required
              >
                <option value="">Mahalle Seçiniz</option>
                {neighborhoodOptions.map((neighborhood) => (
                  <option key={neighborhood.value} value={neighborhood.value}>
                    {neighborhood.label}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-group">
              <label>Adres Detayları *</label>
              <input
                type="text"
                value={buyerAddressDetails}
                onChange={(e) => setBuyerAddressDetails(e.target.value)}
                required
                placeholder="Cadde, Sokak, Bina No"
              />
            </div>

            <div className="form-group">
              <label>Telefon Numarası *</label>
              <input
                type="tel"
                value={buyerPhone}
                onChange={(e) => setBuyerPhone(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label>E-posta *</label>
              <input
                type="email"
                value={buyerEmail}
                onChange={(e) => setBuyerEmail(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label>Kart Üzerindeki İsim *</label>
              <input
                type="text"
                value={cardHolderName}
                onChange={(e) => setCardHolderName(e.target.value)}
                required
                placeholder="Kart üzerindeki ismi giriniz"
              />
            </div>

            <div className="form-group">
              <label>Kart Numarası *</label>
              <input
                type="text"
                value={cardNumber}
                onChange={handleCardNumberChange}
                required
                placeholder="XXXX XXXX XXXX XXXX"
              />
            </div>
            <div className="form-group">
              <label>Son Kullanma Tarihi *</label>
              <div className="expiry">
                <select value={expireMonth} onChange={(e) => setExpireMonth(e.target.value)} required>
                  <option value="">Ay</option>
                  {months.map((month) => (
                    <option key={month} value={month}>
                      {month}
                    </option>
                  ))}
                </select>
                <select value={expireYear} onChange={(e) => setExpireYear(e.target.value)} required>
                  <option value="">Yıl</option>
                  {years.map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="form-group">
              <label>CVV *</label>
              <input
                type="text"
                value={cvv}
                onChange={handleCvvChange}
                required
                placeholder="123"
              />
            </div>

            {installmentOptions.length > 0 && (
    <div className="form-group">
        <label>Taksit Seçenekleri</label>
        <select
            value={selectedInstallment ? selectedInstallment.installment : ''}
            onChange={(e) => {
                const selectedValue = parseInt(e.target.value, 10);
                if (selectedValue === 1) {
                    setSelectedInstallment(null); // Tek Çekim seçildi
                } else {
                    const installment = installmentOptions.find(option => option.installment === selectedValue);
                    setSelectedInstallment(installment);
                }
            }}
        >
            {/* Tek Çekim seçeneği */}
            {installmentOptions.some(option => option.installment === 1) && (
                <option value={1}>Tek Çekim: {parseFloat(amount).toFixed(2)} TL</option>
            )}
            {/* Diğer taksit seçenekleri */}
            {installmentOptions.filter(option => option.installment > 1).map((option) => (
                <option key={option.installment} value={option.installment}>
                    {option.installment} taksit: {option.finalAmount.toFixed(2)} TL
                </option>
            ))}
        </select>
    </div>
)}






            <div className="amount-box">
              Ödenecek Tutar: {amount && parseFloat(amount).toFixed(2)} TL
            </div>

            <p className="discount-link" onClick={() => setIsDiscountVisible(true)}>
              İndirim Kuponunuz mu Var?
            </p>

            {isDiscountVisible && (
              <div className="discount-section">
                <input
                  type="text"
                  value={discountCode}
                  onChange={(e) => setDiscountCode(e.target.value)}
                  placeholder="İndirim Kodu"
                />
                <button type="button" className="apply-btn">
                  İndirimi Uygula
                </button>
              </div>
            )}

            {/* Sözleşme onayı */}
            <div className="terms-checkbox">
              <input
                type="checkbox"
                id="terms-checkbox"
                checked={isChecked}
                onChange={() => {
                  setIsChecked(!isChecked);
                  setErrorMessage('');
                }}
                required
              />
              <label htmlFor="terms-checkbox">
                <span className="terms-text">
                  <span className="link" onClick={() => setShowTerms(true)}>
                    Mesafeli Satış Sözleşmesi
                  </span>{' '}
                  ve{' '}
                  <span className="link" onClick={() => setShowPreInfo(true)}>
                    Ön Bilgilendirme Formu
                  </span>
                  'nu okudum ve onaylıyorum.
                </span>
              </label>
            </div>

            <div className="form-group payment-actions">
              <button
                type="submit"
                disabled={!isChecked || isProcessing}
                className={`pay-btn ${!isChecked ? 'disabled-btn' : ''}`}
              >
                Ödeme Yap
              </button>
            </div>

            {errorMessage && <p className="error-message">{errorMessage}</p>}
            {message && <p>{message}</p>}
          </form>
        </div>
      </div>

      {/* Mesafeli Satış Sözleşmesi Popup */}
      {showTerms && (
        <div className="popup">
          <div className="popup-content">
            <h3>Mesafeli Satış Sözleşmesi</h3>
            <p>Bu sözleşme ...</p>
            <button className="close-button" onClick={() => setShowTerms(false)}>
              Kapat
            </button>
          </div>
        </div>
      )}

      {/* Ön Bilgilendirme Formu Popup */}
      {showPreInfo && (
        <div className="popup">
          <div className="popup-content">
            <h3>Ön Bilgilendirme Formu</h3>
            <p>Bu form ...</p>
            <button className="close-button" onClick={() => setShowPreInfo(false)}>
              Kapat
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PaymentForm;
