// src/pages/Classes.jsx

import React, { useState, useEffect } from "react";
import SearchBar from "../../customComponents/SearchBar";
import AttendanceCard from "../../customComponents/AttendanceCard";
import styles from "./Classes.module.css";

const Classes = () => {
  const [classes, setClasses] = useState([]);
  const [selectedClass, setSelectedClass] = useState(null);
  const [students, setStudents] = useState([]);
  const [showAttendance, setShowAttendance] = useState(false);
  const [showHomework, setShowHomework] = useState(false);

  // Fetch classes on component mount
  useEffect(() => {
    fetchClasses();
  }, []);

  // Fetch all classes for the teacher
  const fetchClasses = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch('https://backend.ubicraft.org/api/teacher/classes', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (response.ok) {
        const data = await response.json();
        setClasses(data);
      } else {
        console.error('Failed to fetch classes:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching classes:', error);
    }
  };

  // Fetch students for a specific class
  const fetchStudents = async (classId) => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`https://backend.ubicraft.org/api/teacher/class/${classId}/students`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (response.ok) {
        const data = await response.json();
        setStudents(data);
        setSelectedClass(classId);
        setShowAttendance(false);
        setShowHomework(false);
      } else {
        console.error('Failed to fetch students:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching students:', error);
    }
  };

  // Fetch attendance for a specific class
  const fetchAttendance = async (classId) => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`https://backend.ubicraft.org/api/teacher/class/${classId}/students`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (response.ok) {
        const data = await response.json();
        setStudents(data);
        setSelectedClass(classId);
        setShowAttendance(true);
        setShowHomework(false);
      } else {
        console.error('Failed to fetch attendance:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching attendance:', error);
    }
  };

  // Fetch homework for a specific class
  const fetchHomework = async (classId) => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`https://backend.ubicraft.org/api/teacher/class/${classId}/homework`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (response.ok) {
        const data = await response.json();
        const studentsWithGrades = data.map(student => ({
          ...student,
          grades: student.grades || Array.from({ length: 32 }, (_, week) => ({ week: week + 1, grade: "" }))
        }));
        setStudents(studentsWithGrades);
        setSelectedClass(classId);
        setShowHomework(true);
        setShowAttendance(false);
      } else {
        console.error('Failed to fetch homework:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching homework:', error);
    }
  };

  // Update homework grade for a student
  const updateHomeworkGrade = async (studentId, week, grade) => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`https://backend.ubicraft.org/api/teacher/class/${selectedClass}/homework`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ studentId, week, grade })
      });

      if (response.ok) {
        setStudents((prevStudents) => 
          prevStudents.map((student) => 
            student.id === studentId
              ? {
                  ...student,
                  grades: student.grades.map((g) =>
                    g.week === week ? { ...g, grade } : g
                  ),
                }
              : student
          )
        );
      } else {
        console.error('Failed to update homework grade:', response.statusText);
      }
    } catch (error) {
      console.error('Error updating homework grade:', error);
    }
  };

  // Render grade selection dropdown
  const renderGrades = (student, week) => {
    const grade = student.grades?.find(g => g.week === week)?.grade || '';
    const gradeClass = grade.toLowerCase().replace(" ", "-");
    return (
      <div className={styles.gradeContainer} key={week}>
        <div className={styles.weekNumber}>{week}</div>
        <select
          value={grade}
          onChange={(e) => updateHomeworkGrade(student.id, week, e.target.value)}
          className={`${styles.selectGrade} ${styles[gradeClass]}`}
        >
          <option value="">Notlandırma</option>
          <option value="Excellent" className={styles.excellent}>Kusursuz</option>
          <option value="Very Good" className={styles.veryGood}>Çok iyi</option>
          <option value="Good" className={styles.good}>İyi</option>
          <option value="Average" className={styles.average}>Ortalama</option>
          <option value="Poor" className={styles.poor}>Zayıf</option>
        </select>
      </div>
    );
  };

  return (
    <div className="w-full flex flex-col gap-4 px-4 lg:px-16">
      <SearchBar />
      {selectedClass === null ? (
        // Display list of classes
        classes.map((classItem) => (
          <div key={classItem.id} className="flex flex-col md:flex-row md:items-start items-center bg-white rounded-3xl px-4 sm:px-6 py-6 gap-4 bg-[#301934]">
            <div className={`${styles.imagePlaceholder} h-48 w-full md:w-64`}></div>
            <div className="flex flex-col px-4 py-4">
              <p className="mt-3 font-poppins font-bold text-2xl sm:text-3xl text-primary text-white">
                {classItem.name}
              </p>
              <div className="flex gap-2 mt-4">
                <button 
                  className={styles.buttonCustom}
                  onClick={() => fetchStudents(classItem.id)}
                >
                  Öğrencileri Görüntüle
                </button>
                <button 
                  className={styles.buttonCustom}
                  onClick={() => fetchAttendance(classItem.id)}
                >
                  Yoklama
                </button>
                <button 
                  className={styles.buttonCustom}
                  onClick={() => fetchHomework(classItem.id)}
                >
                  Ödev Kontrolü
                </button>
              </div>
            </div>
          </div>
        ))
      ) : showHomework ? (
        // Display homework
        <div className={styles.tableContainer}>
          <button 
            onClick={() => {
              setSelectedClass(null);
              setShowAttendance(false);
              setShowHomework(false);
            }} 
            className={styles.buttonBack}
          >
            Geri
          </button>

          <h2 className="mt-3 font-poppins font-bold text-2xl sm:text-3xl text-primary">Ödev Kontrolü</h2>
          <div className="flex flex-col gap-4 mx-6 mt-4">
            {students.map((student) => (
              <div key={student.id} className="flex flex-col mb-4">
                <p className="font-poppins font-bold text-lg sm:text-xl text-primary mb-2">
                  {student.name}
                </p>
                <div className={`${styles.grid} ${styles.gridCols16} ${styles.gap4}`}>
                  {Array.from({ length: 32 }, (_, week) => (
                    renderGrades(student, week + 1)
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : showAttendance ? (
        // Display attendance
        <div className={styles.tableContainer}>
          <button 
            onClick={() => {
              setSelectedClass(null);
              setShowAttendance(false);
              setShowHomework(false);
            }} 
            className={styles.buttonBack}
          >
            Geri
          </button>

          <h2 className="mt-3 font-poppins font-bold text-2xl sm:text-3xl text-primary">Yoklama</h2>
          <div className="flex flex-col gap-4 mx-6 mt-4">
            {students.map((student) => (
              <AttendanceCard
                key={student.id}
                Name={student.name}
                studentId={student.id}
                classId={selectedClass}
              />
            ))}
          </div>
        </div>
      ) : (
        // Display students list
        <div className={styles.tableContainer}>
          <button 
            onClick={() => {
              setSelectedClass(null);
              setShowAttendance(false);
              setShowHomework(false);
            }} 
            className={styles.buttonBack}
          >
            Geri
          </button>

          <h2 className="mt-3 font-poppins font-bold text-2xl sm:text-3xl text-primary">Öğrenciler</h2>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>ID</th>
                <th>İSİM</th>
                <th>EMAIL</th>
                <th>TELEFON NUMARASI</th>
                <th>VELİ İSMİ</th>
                <th>BAKİYE</th>
              </tr>
            </thead>
            <tbody>
              {students.map((student) => (
                <tr key={student.id}>
                  <td>{student.id}</td>
                  <td>{student.name}</td>
                  <td>{student.email}</td>
                  <td>{student.phone_number}</td>
                  <td>{student.guardian_name}</td>
                  <td>{student.balance}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default Classes;
