import React, { useEffect } from "react";
import Navbar from "../../components/Landing/NavBar";
import Footer from "../../components/Landing/Footer";
import PythonP from "../../components/PythonStartPage/PythonStart";
import PythonP2 from "../../components/PythonStartPage/PythonStart2";
import PythonP3 from "../../components/PythonStartPage/PythonStart3";
import Company from "../../components/Pricing/Company";
import TrialLessonPopup from "../../components/FreeLessonPopup/FreeLesson"; // Popup componentini import ediyoruz
import SlidingBanner from "../../components/SlidingBanner/SlidingBanner"; // Banner'ı import edin
export default function Pricingpage() {
  // Sayfa yüklendiğinde popup'ın görünmesi için useEffect kullanıyoruz
  useEffect(() => {
    // Popup'ın hemen görünmesi için setTimeout kullanmıyoruz
    document.body.style.overflow = 'hidden'; // Popup açıkken sayfanın scroll'unu engelliyoruz
    
    // Cleanup function
    return () => {
      document.body.style.overflow = 'unset'; // Component unmount olduğunda scroll'u tekrar aktif ediyoruz
    };
  }, []);

  return (
    <>
      <SlidingBanner /> {/* Banner'ı en üste ekleyin */}
      <Navbar />
      <PythonP />
      <PythonP2 />
      <PythonP3 />
      <Company />
      <Footer />
      <TrialLessonPopup /> {/* Popup componentini ekliyoruz */}
    </>
  );
}