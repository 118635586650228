import React from 'react';
import './SlidingBanner.css';

const SlidingBanner = () => {
  const bannerText = [
    "Python Start kursumuzda sınırlı indirimi kaçırmayın! ",
    "Ücretsiz deneme dersimizle kayıt gerekmeden Ubicraft Platformu deneyimleyin! "
  ];

  return (
    <div className="main-content">
    <div className="sliding_banner_container">
      <div className="sliding_banner_content">
        <div className="sliding_banner_track">
          {/* İlk set */}
          <span>{bannerText[0]}</span>
          <span>{bannerText[1]}</span>
          {/* Kesintisiz görünüm için tekrar */}
          <span>{bannerText[0]}</span>
          <span>{bannerText[1]}</span>
        </div>
      </div>
    </div>
    </div>
  );
};

export default SlidingBanner;