import React from "react";
import FAQS from "../../components/Pricing/FAQS";
import Navbar from "../../components/Landing/NavBar";
import Footer from "../../components/Landing/Footer";
import RegestrationSection from "../../components/Landing/RegestrationSection";
import Company from "../../components/Pricing/Company";
import TrialLessonPopup from "../../components/FreeLessonPopup/FreeLesson"; // Popup componentini import ediyoruz
import SlidingBanner from "../../components/SlidingBanner/SlidingBanner"; // Banner'ı import edin
export default function Pricingpage() {
  return (
    <>
      <SlidingBanner /> {/* Banner'ı en üste ekleyin */}
      <Navbar />
      <FAQS />
      <RegestrationSection />
      <Company />
      <Footer />
    </>
  );
}
