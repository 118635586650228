import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './yoklama.css';

const AttendanceCard = ({ Name, studentId, classId }) => {
  const totalWeeks = 32;
  const [weeks, setWeeks] = useState(Array(totalWeeks).fill('grey'));

  useEffect(() => {
    const fetchAttendance = async () => {
      try {
        const response = await fetch(`https://backend.ubicraft.org/api/attendance/${studentId}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        const newWeeks = Array(totalWeeks).fill('grey');
        data.forEach(({ week, status }) => {
          const color = status === 'Katıldı' ? 'green' 
                      : status === 'Katılmadı' ? 'red' 
                      : status === 'İzinli' ? 'yellow' 
                      : 'grey';
          if (week >= 1 && week <= totalWeeks) {
            newWeeks[week - 1] = color;
          }
        });
        setWeeks(newWeeks);
      } catch (error) {
        console.error('Error fetching attendance:', error);
      }
    };

    fetchAttendance();
  }, [studentId, classId, totalWeeks]);

  const rows = [];
  const weeksPerRow = 16;
  for (let i = 0; i < weeks.length; i += weeksPerRow) {
    rows.push(weeks.slice(i, i + weeksPerRow));
  }

  return (
    <div className="attendance-card">
      <div className="name-label">{Name}</div>
      <div className="weeks-container">
        {rows.map((row, rowIndex) => (
          <div key={rowIndex}>
            <div className="module-row">
              {Array.from({ length: 4 }, (_, modIndex) => (
                <div className="module-label" key={modIndex}>
                  {`M${rowIndex * 4 + modIndex + 1}`}
                </div>
              ))}
            </div>
            <div className="week-row">
              {row.map((color, index) => (
                <div className="week2-container" key={rowIndex * weeksPerRow + index}>
                  <div className={`week-box ${color}`}>
                    <span className="week-number">{rowIndex * weeksPerRow + index + 1}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const Yoklama = () => {
  const { userId, classId } = useParams();
  const [studentName, setStudentName] = useState('');

  useEffect(() => {
    const fetchStudentName = async () => {
      try {
        const response = await fetch(`https://backend.ubicraft.org/api/students/${userId}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setStudentName(data.name);
      } catch (error) {
        console.error('Error fetching student name:', error);
      }
    };

    fetchStudentName();
  }, [userId]);

  return (
    <div className="attendance-page">
      <h1 className="attendance-title">Katılım Bilgisi</h1>
      <div className="attendance-info">
        <div className="info-box">
          <div className="info-item">
            <div className="color-box green"></div>
            <span>Katıldı</span>
          </div>
          <div className="info-item">
            <div className="color-box red"></div>
            <span>Katılmadı</span>
          </div>
          <div className="info-item">
            <div className="color-box yellow"></div>
            <span>İzinli</span>
          </div>
        </div>
      </div>
      <AttendanceCard Name={studentName} studentId={userId} classId={classId} />
    </div>
  );
};

export default Yoklama;