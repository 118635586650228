// PythonQuiz.js
import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './PythonQuiz.css';
import { CheckCircle2, XCircle, Trophy, BarChart3, ArrowRight } from 'lucide-react';
import QuizResult from './QuizResult';

const questions = [
  {
    id: 1,
    type: 'drag-drop',
    question: "Aşağıdaki OpenCV kodunda boşlukları doğru şekilde doldurunuz:",
    codeBlock: `import cv2
import numpy as np

# Resmi yükleme
image = cv2.imread('/content/g_i/car.jpg')

# Resmi HSV renk uzayına dönüştürme
hsv_image = [CONVERT_COLOR]

# Kırmızı renk için HSV aralığını belirleme
lower_red1 = np.array([0, 120, 70])
upper_red1 = [UPPER_RED1]

lower_red2 = [LOWER_RED2]
upper_red2 = np.array([180, 255, 255])

# Maske oluşturma
mask1 = cv2.inRange(hsv_image, lower_red1, upper_red1)
mask2 = cv2.inRange(hsv_image, lower_red2, upper_red2)
mask = [COMBINE_MASKS]

# Morfolojik işlemler
kernel = [KERNEL_SIZE]
mask = cv2.dilate(mask, kernel, iterations=3)
mask = [ERODE_MASK]`,
    blanks: [
      { id: 'blank1', correct: 'cv2.cvtColor(image, cv2.COLOR_BGR2HSV)', placeholder: '[CONVERT_COLOR]' },
      { id: 'blank2', correct: 'np.array([10, 255, 255])', placeholder: '[UPPER_RED1]' },
      { id: 'blank3', correct: 'np.array([170, 120, 70])', placeholder: '[LOWER_RED2]' },
      { id: 'blank4', correct: 'mask1 | mask2', placeholder: '[COMBINE_MASKS]' },
      { id: 'blank5', correct: 'np.ones((5, 5), np.uint8)', placeholder: '[KERNEL_SIZE]' },
      { id: 'blank6', correct: 'cv2.erode(mask, kernel, iterations=2)', placeholder: '[ERODE_MASK]' }
    ],
    options: [
      'cv2.cvtColor(image, cv2.COLOR_BGR2HSV)',
      'np.array([10, 255, 255])',
      'np.array([170, 120, 70])',
      'mask1 | mask2',
      'np.ones((5, 5), np.uint8)',
      'cv2.erode(mask, kernel, iterations=2)',
      'cv2.COLOR_RGB2HSV(image)',
      'mask1 + mask2',
      'np.zeros((5, 5), np.uint8)'
    ],
    explanation: "Bu kod, bir görüntüdeki kırmızı nesneleri tespit etmek için OpenCV ve NumPy kütüphanelerini kullanır. HSV renk uzayında kırmızı rengi iki farklı aralıkta tanımlar ve morfolojik işlemlerle görüntüyü iyileştirir."
  },
  {
    id: 2,
    type: 'multiple-choice',
    question: "cv2.cvtColor(image, cv2.COLOR_BGR2HSV) fonksiyonunun amacı nedir?",
    options: [
      "Görüntüyü BGR renk uzayından HSV renk uzayına dönüştürmek",
      "Görüntüyü siyah-beyaz yapmak",
      "Görüntüyü RGB renk uzayına dönüştürmek",
      "Görüntünün kontrastını artırmak"
    ],
    correctAnswer: "Görüntüyü BGR renk uzayından HSV renk uzayına dönüştürmek",
    explanation: "cvtColor fonksiyonu, renk uzayları arasında dönüşüm yapar. BGR2HSV parametresi, BGR (Blue-Green-Red) formatından HSV (Hue-Saturation-Value) formatına dönüşümü sağlar."
  },
  {
    id: 3,
    type: 'multiple-choice',
    question: "HSV renk uzayında kırmızı rengin iki farklı aralıkta tanımlanmasının sebebi nedir?",
    options: [
      "Kırmızı renk HSV renk çemberinde başlangıç ve bitiş noktalarında yer aldığı için",
      "Farklı tonlardaki kırmızıları ayırt etmek için",
      "Görüntü işleme hızını artırmak için",
      "Bellek kullanımını optimize etmek için"
    ],
    correctAnswer: "Kırmızı renk HSV renk çemberinde başlangıç ve bitiş noktalarında yer aldığı için",
    explanation: "HSV renk uzayında, kırmızı renk hem 0° civarında hem de 180° civarında bulunur. Bu nedenle tam kapsama için iki aralık kullanılması gerekir."
  },
  {
    id: 4,
    type: 'multiple-choice',
    question: "cv2.dilate() ve cv2.erode() fonksiyonlarının sırayla kullanılmasının amacı nedir?",
    options: [
      "Gürültüyü azaltıp nesne kenarlarını düzeltmek",
      "Görüntüyü büyütüp küçültmek",
      "Renk kontrastını artırmak",
      "Görüntü çözünürlüğünü değiştirmek"
    ],
    correctAnswer: "Gürültüyü azaltıp nesne kenarlarını düzeltmek",
    explanation: "Dilate işlemi görüntüyü genişletir ve boşlukları doldurur, ardından erode işlemi fazlalıkları temizler. Bu sıralama, gürültüyü azaltır ve nesne sınırlarını düzeltir."
  },
  {
    id: 5,
    type: 'multiple-choice',
    question: "NumPy kütüphanesinin bu kodda kullanılma amacı nedir?",
    options: [
      "Çok boyutlu diziler oluşturmak ve matematiksel işlemler yapmak",
      "Görüntüyü kaydetmek",
      "Renk dönüşümü yapmak",
      "Görüntüyü ekranda göstermek"
    ],
    correctAnswer: "Çok boyutlu diziler oluşturmak ve matematiksel işlemler yapmak",
    explanation: "NumPy, renk eşik değerlerini tutan dizileri (np.array) ve morfolojik işlemler için kernel matrisini (np.ones) oluşturmada kullanılır."
  },
  {
    id: 6,
    type: 'multiple-choice',
    question: "Maskelerin birleştirilmesinde | (OR) operatörünün kullanılma sebebi nedir?",
    options: [
      "Her iki maskedeki beyaz pikselleri tek bir maskede birleştirmek",
      "Maskeleri çarpmak",
      "Maskeleri birbirinden çıkarmak",
      "Maskelerin ortalamasını almak"
    ],
    correctAnswer: "Her iki maskedeki beyaz pikselleri tek bir maskede birleştirmek",
    explanation: "OR operatörü (|), iki maskedeki beyaz pikselleri (1 değerlerini) birleştirir, böylece her iki kırmızı ton aralığını da içeren tek bir maske elde edilir."
  }
];

const CodeBlock = ({ code, questionId, blanks, filledBlanks, onDrop, showResults }) => {
  const handleDragOver = (e) => {
    e.preventDefault();
    e.target.classList.add('drag-over');
  };

  const handleDragLeave = (e) => {
    e.target.classList.remove('drag-over');
  };

  const handleDrop = (e, blankId) => {
    e.preventDefault();
    e.target.classList.remove('drag-over');
    const droppedText = e.dataTransfer.getData('text/plain');
    onDrop(questionId, blankId, droppedText);
  };

  const lines = code.split('\n');
  return (
    <pre className="code-block">
      <code>
        {lines.map((line, lineIndex) => {
          let processedLine = line;
          blanks.forEach(blank => {
            const value = filledBlanks[`${questionId}-${blank.id}`] || blank.placeholder;
            const isCorrect = showResults && value === blank.correct;
            const isWrong = showResults && value !== blank.placeholder && value !== blank.correct;
            
            const className = `code-blank ${
              isCorrect ? 'correct-blank' : isWrong ? 'wrong-blank' : ''
            }`;

            processedLine = processedLine.replace(
              blank.placeholder,
              `<span 
                class="${className}"
                data-blank-id="${blank.id}"
                draggable="true"
              >${value}</span>`
            );
          });
          return (
            <div
              key={lineIndex}
              className="code-line"
              dangerouslySetInnerHTML={{ __html: processedLine }}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={(e) => {
                const blankId = e.target.closest('.code-blank')?.dataset.blankId;
                if (blankId) handleDrop(e, blankId);
              }}
            />
          );
        })}
      </code>
    </pre>
  );
};

const DragOption = ({ option, onDragStart }) => {
  return (
    <div
      className="drag-option"
      draggable="true"
      onDragStart={(e) => {
        e.dataTransfer.setData('text/plain', option);
        onDragStart(option);
      }}
    >
      {option}
    </div>
  );
};

const QuizResultPopup = ({ 
  score, 
  total, 
  correctAnswers, 
  wrongAnswers, 
  emptyAnswers, 
  submitStatus,
  onClose,
  onRetry,
  onNavigateToResults
}) => {
  const percentage = Math.round((score / total) * 100);
  
  return (
    <div className="fixed inset-0 bg-gray-900/50 backdrop-blur-sm flex items-center justify-center z-50">
      <div className="bg-white rounded-2xl shadow-xl w-full max-w-lg mx-4 overflow-hidden transform transition-all">
        <div className="p-6">
          {/* Header */}
          <div className="text-center mb-6">
            {percentage >= 70 ? (
              <Trophy className="w-16 h-16 mx-auto mb-4 text-yellow-500" />
            ) : (
              <BarChart3 className="w-16 h-16 mx-auto mb-4 text-blue-500" />
            )}
            <h3 className="text-2xl font-bold text-gray-900">
              Sınav Tamamlandı!
            </h3>
          </div>

          {/* Score Circle */}
          <div className="flex justify-center mb-8">
            <div className="relative">
              <svg className="w-32 h-32">
                <circle
                  className="text-gray-200"
                  strokeWidth="8"
                  stroke="currentColor"
                  fill="transparent"
                  r="56"
                  cx="64"
                  cy="64"
                />
                <circle
                  className={`${
                    percentage >= 70 ? 'text-green-500' : 'text-blue-500'
                  }`}
                  strokeWidth="8"
                  strokeLinecap="round"
                  stroke="currentColor"
                  fill="transparent"
                  r="56"
                  cx="64"
                  cy="64"
                  style={{
                    strokeDasharray: '352.8571428571429',
                    strokeDashoffset: 352.8571428571429 * (1 - percentage / 100),
                    transform: 'rotate(-90deg)',
                    transformOrigin: '50% 50%'
                  }}
                />
              </svg>
              <div className="absolute inset-0 flex items-center justify-center">
                <span className="text-3xl font-bold">
                  {percentage}%
                </span>
              </div>
            </div>
          </div>

          {/* Statistics */}
          <div className="grid grid-cols-3 gap-4 mb-6">
            <div className="text-center p-3 bg-green-50 rounded-lg">
              <CheckCircle2 className="w-6 h-6 text-green-500 mx-auto mb-1" />
              <div className="text-xl font-bold text-green-600">{correctAnswers}</div>
              <div className="text-sm text-green-600">Doğru</div>
            </div>
            <div className="text-center p-3 bg-red-50 rounded-lg">
              <XCircle className="w-6 h-6 text-red-500 mx-auto mb-1" />
              <div className="text-xl font-bold text-red-600">{wrongAnswers}</div>
              <div className="text-sm text-red-600">Yanlış</div>
            </div>
            <div className="text-center p-3 bg-gray-50 rounded-lg">
              <div className="w-6 h-6 text-gray-400 mx-auto mb-1">-</div>
              <div className="text-xl font-bold text-gray-600">{emptyAnswers}</div>
              <div className="text-sm text-gray-600">Boş</div>
            </div>
          </div>

          {/* Status Message */}
          {submitStatus && (
            <div className={`text-center p-3 rounded-lg mb-6 ${
              submitStatus === 'success' 
                ? 'bg-green-50 text-green-700'
                : 'bg-red-50 text-red-700'
            }`}>
              {submitStatus === 'success' 
                ? 'Sonuçlarınız başarıyla kaydedildi!'
                : submitStatus.replace('error: ', 'Hata: ')}
            </div>
          )}

          {/* Actions */}
          <div className="flex flex-col gap-3">
            <button
              onClick={onNavigateToResults}
              className="w-full px-4 py-3 bg-blue-600 text-white rounded-lg font-semibold hover:bg-blue-700 transition-colors flex items-center justify-center gap-2"
            >
              Sonuçlar Sayfasına Git
              <ArrowRight className="w-4 h-4" />
            </button>
            <button
              onClick={onRetry}
              className="w-full px-4 py-3 bg-gray-100 text-gray-700 rounded-lg font-semibold hover:bg-gray-200 transition-colors"
            >
              Tekrar Dene
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const PythonQuiz = () => {
  const { userId, examId } = useParams();
  const navigate = useNavigate();
  const [showResults, setShowResults] = useState(false);
  const [showResultPopup, setShowResultPopup] = useState(false);
  const [filledBlanks, setFilledBlanks] = useState({});
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [draggedItem, setDraggedItem] = useState(null);
  const [submitStatus, setSubmitStatus] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleDrop = (questionId, blankId, value) => {
    setFilledBlanks(prev => ({
      ...prev,
      [`${questionId}-${blankId}`]: value
    }));
  };

  const handleDragStart = (option) => {
    setDraggedItem(option);
  };

  const handleOptionSelect = (questionId, option) => {
    setSelectedAnswers(prev => ({
      ...prev,
      [questionId]: option
    }));
  };

  const calculateScore = () => {
    let correctAnswers = 0;
    let wrongAnswers = 0;
    let emptyAnswers = 0;
    let total = 0;
  
    questions.forEach((question) => {
      if (question.type === 'drag-drop') {
        question.blanks.forEach((blank) => {
          total++;
          const userAnswer = filledBlanks[`${question.id}-${blank.id}`];
          if (userAnswer === blank.correct) {
            correctAnswers++;
          } else if (!userAnswer || userAnswer === blank.placeholder) {
            emptyAnswers++;
          } else {
            wrongAnswers++;
          }
        });
      } else if (question.type === 'multiple-choice') {
        total++;
        const userAnswer = selectedAnswers[question.id];
        if (userAnswer === question.correctAnswer) {
          correctAnswers++;
        } else if (!userAnswer) {
          emptyAnswers++;
        } else {
          wrongAnswers++;
        }
      }
    });

    return {
      score: Math.round((correctAnswers / total) * 100),
      total,
      correctAnswers,
      wrongAnswers,
      emptyAnswers
    };
  };

  const handleSubmit = async () => {
    if (isSubmitting) return;
    
    setIsSubmitting(true);
    const { score, correctAnswers, wrongAnswers, emptyAnswers } = calculateScore();

    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`https://backend.ubicraft.org/api/student/${userId}/exams/${examId}/results`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          correct_answers: correctAnswers,
          wrong_answers: wrongAnswers,
          empty_answers: emptyAnswers,
          score: score
        })
      });

      if (response.ok) {
        setSubmitStatus('success');
        setShowResults(true);
        setShowResultPopup(true);
      } else {
        const error = await response.json();
        setSubmitStatus(`error: ${error.message || 'Beklenmeyen bir hata oluştu'}`);
      }
    } catch (error) {
      console.error('Gönderim hatası:', error);
      setSubmitStatus('error: Sunucuya bağlanılamadı');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleRetry = () => {
    setShowResultPopup(false);
    setShowResults(false);
    setFilledBlanks({});
    setSelectedAnswers({});
    setSubmitStatus(null);
  };

  return (
    <div className="quiz-container">
      <div className="quiz-header">
        <h1 className="quiz-title">Modül 1 Sınavı</h1>
        <p className="quiz-description">
          Görüntü işleme kodunu tamamlayın ve çoktan seçmeli soruları cevaplayın.
        </p>
      </div>

      <div className="questions-container">
        {questions.map((question) => (
          <div key={question.id} className="question-card">
            <div className="question-header">
              <span className="question-number">Soru {question.id}</span>
              <h3 className="question-text">{question.question}</h3>
            </div>

            {question.type === 'drag-drop' ? (
              <>
                <CodeBlock
                  code={question.codeBlock}
                  questionId={question.id}
                  blanks={question.blanks}
                  filledBlanks={filledBlanks}
                  onDrop={handleDrop}
                  showResults={showResults}
                />
                <div className="options-container drag-drop">
                  {question.options.map((option, index) => (
                    <DragOption
                      key={index}
                      option={option}
                      onDragStart={handleDragStart}
                    />
                  ))}
                </div>
              </>
            ) : (
              <div className="multiple-choice-container">
                {question.options.map((option, index) => (
                  <button
                    key={index}
                    className={`
                      choice-button
                      ${selectedAnswers[question.id] === option ? 'selected' : ''}
                      ${showResults && option === question.correctAnswer ? 'correct' : ''}
                      ${showResults && selectedAnswers[question.id] === option && 
                        option !== question.correctAnswer ? 'wrong' : ''}
                    `}
                    onClick={() => handleOptionSelect(question.id, option)}
                    disabled={showResults}
                  >
                    <span className="choice-letter">{String.fromCharCode(65 + index)}</span>
                    <span className="choice-text">{option}</span>
                    {showResults && option === question.correctAnswer && (
                      <span className="choice-icon">✓</span>
                    )}
                    {showResults && selectedAnswers[question.id] === option && 
                      option !== question.correctAnswer && (
                      <span className="choice-icon wrong">✗</span>
                    )}
                  </button>
                ))}
              </div>
            )}

            {showResults && (
              <div className="explanation-box">
                <h4>Açıklama:</h4>
                <p>{question.explanation}</p>
              </div>
            )}
          </div>
        ))}
      </div>

      <div className="submit-container">
        {!showResults ? (
          <button
            onClick={handleSubmit}
            className="submit-button"
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Gönderiliyor...' : 'Sınavı Bitir'}
            </button>
        ) : null}
      </div>

      {showResultPopup && (
        <QuizResult
          score={calculateScore().score}
          total={calculateScore().total}
          correctAnswers={calculateScore().correctAnswers}
          wrongAnswers={calculateScore().wrongAnswers}
          emptyAnswers={calculateScore().emptyAnswers}
          submitStatus={submitStatus}
          onClose={() => setShowResultPopup(false)}
          onRetry={handleRetry}
          onNavigateToResults={() => navigate(`/student/${userId}/exams`)}
        />
      )}

      {showResults && !showResultPopup && (
        <div className="results-summary">
          <div className="results-stats">
            <div className="stat-item">
              <span className="stat-label">Toplam Soru</span>
              <span className="stat-value">{calculateScore().total}</span>
            </div>
            <div className="stat-item">
              <span className="stat-label">Doğru</span>
              <span className="stat-value correct">{calculateScore().correctAnswers}</span>
            </div>
            <div className="stat-item">
              <span className="stat-label">Yanlış</span>
              <span className="stat-value wrong">{calculateScore().wrongAnswers}</span>
            </div>
            <div className="stat-item">
              <span className="stat-label">Boş</span>
              <span className="stat-value empty">{calculateScore().emptyAnswers}</span>
            </div>
          </div>
          
          <div className="results-actions">
            <button
              onClick={() => navigate(`/student/${userId}/exams`)}
              className="result-action-button primary"
            >
              <ArrowRight className="w-4 h-4 mr-2" />
              Sonuçlar Sayfasına Git
            </button>
            <button
              onClick={handleRetry}
              className="result-action-button secondary"
            >
              Tekrar Dene
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PythonQuiz;