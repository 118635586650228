import React, { useState } from 'react';
import { X, Clock, Users, Star, ArrowRight } from 'lucide-react';
import './FreeLesson.css';
import lesson from './free.png'; // Resmi import ediyoruz

const TrialLessonPopup = () => {
  const [isOpen, setIsOpen] = useState(true);

  const handleClose = () => {
    setIsOpen(false);
    document.body.style.overflow = 'unset';
  };

  if (!isOpen) return null;

  return (
    <div className="trial_popup_overlay">
      <div className="trial_popup_container">
        {/* Kapatma butonu */}
        <button 
          onClick={handleClose}
          className="trial_popup_close_btn"
        >
          <X size={24} />
        </button>

        <div className="trial_popup_content_wrapper">
          {/* Sol Bölüm - Resim */}
          <div className="trial_popup_image_section">
            <div className="trial_popup_free_tag">
              Ücretsiz Fırsat!
            </div>
            <img 
              src={lesson}  // Yerel resmi kullanıyoruz
              alt="Online Eğitim" 
              className="trial_popup_image"
            />
          </div>

          {/* Sağ Bölüm - İçerik */}
          <div className="trial_popup_content_section">
            <h2 className="trial_popup_title">
              Kayıt olmadan ilk dersinizi ücretsiz deneyin!
            </h2>
            
            <p className="trial_popup_subtitle">
              Öğrenme yolculuğunuza ücretsiz bir başlangıç yapın
            </p>

            <div className="trial_popup_features_list">
              <div className="trial_popup_feature_card">
                <Clock className="trial_popup_feature_icon" size={20} />
                <div>
                  <h3 className="trial_popup_feature_title">60 Dakika Ücretsiz</h3>
                  <p className="trial_popup_feature_desc">Birebir Online Ders</p>
                </div>
              </div>

              <div className="trial_popup_feature_card">
                <Users className="trial_popup_feature_icon" size={20} />
                <div>
                  <h3 className="trial_popup_feature_title">Uzman Eğitmenler</h3>
                  <p className="trial_popup_feature_desc">Alanında Deneyimli Kadro</p>
                </div>
              </div>

              <div className="trial_popup_feature_card">
                <Star className="trial_popup_feature_icon" size={20} />
                <div>
                  <h3 className="trial_popup_feature_title">İnteraktif Öğrenme</h3>
                  <p className="trial_popup_feature_desc">Kişiye Özel Program</p>
                </div>
              </div>
            </div>

            <div className="trial_popup_button_group">
                <button 
                    onClick={() => {
                    window.location.href = 'https://form.jotform.com/243057905406961';
                    handleClose();
                    }}
                    className="trial_popup_primary_btn"
                >
                    <span>ÜCRETSİZ DERS AL</span>
                    <ArrowRight size={20} />
                </button>

                <button 
                    onClick={handleClose}
                    className="trial_popup_secondary_btn"
                >
                    Daha Sonra Hatırlat
                </button>
                </div>
            <p className="trial_popup_disclaimer">
              * Sınırlı sayıda kontenjan için geçerlidir
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrialLessonPopup;