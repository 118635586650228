import React, { useState, useEffect } from 'react';

const TeacherManagement = () => {
  const [teachers, setTeachers] = useState([]);
  const [classes, setClasses] = useState([]);
  const [selectedTeacher, setSelectedTeacher] = useState(null);
  const [selectedClass, setSelectedClass] = useState(null);

  // Öğretmenleri fetch etme
  useEffect(() => {
    const fetchTeachers = async () => {
      try {
        const response = await fetch('https://backend.ubicraft.org/api/students');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        const filteredTeachers = data.filter(student => student.role === 'teacher');
        setTeachers(filteredTeachers);
      } catch (error) {
        console.error('Error fetching teachers:', error);
      }
    };

    fetchTeachers();
  }, []);

  // Sınıfları fetch etme
  useEffect(() => {
    const fetchClasses = async () => {
      try {
        const response = await fetch('https://backend.ubicraft.org/api/classes');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setClasses(data);
      } catch (error) {
        console.error('Error fetching classes:', error);
      }
    };

    fetchClasses();
  }, []);

  const handleAssignTeacher = async () => {
    if (!selectedTeacher || !selectedClass) {
      alert('Lütfen bir öğretmen ve sınıf seçin');
      return;
    }

    try {
      const response = await fetch('https://backend.ubicraft.org/api/teacher-management/assign-teacher', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          teacherId: selectedTeacher.id,
          classId: selectedClass.id,
        }),
      });
  
      if (!response.ok) {
        if (response.status === 400) {
          const errorText = await response.text();
          alert(errorText); // Öğretmenin zaten atanmış olduğunu belirten mesaj
        } else {
          throw new Error('Network response was not ok');
        }
      } else {
        alert('Öğretmen sınıfa başarıyla atandı');
      }
    } catch (error) {
      console.error('Error assigning teacher:', error);
      alert('Öğretmeni sınıfa atarken bir hata oluştu');
    }
  };
  

  return (
    <div className="teacher-management">
      <h2>Teacher Management</h2>

      <div className="selection-section">
        <div>
          <label>Öğretmen Seçin:</label>
          <select onChange={(e) => setSelectedTeacher(teachers.find(t => t.id === parseInt(e.target.value)))}>
            <option value="">-- Öğretmen Seçin --</option>
            {teachers.map((teacher) => (
              <option key={teacher.id} value={teacher.id}>
                {teacher.name}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label>Sınıf Seçin:</label>
          <select onChange={(e) => setSelectedClass(classes.find(c => c.id === parseInt(e.target.value)))}>
            <option value="">-- Sınıf Seçin --</option>
            {classes.map((classItem) => (
              <option key={classItem.id} value={classItem.id}>
                {classItem.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      <button onClick={handleAssignTeacher}>Öğretmeni Ata</button>
    </div>
  );
};

export default TeacherManagement;
