import React, { useState, useEffect } from 'react';
import './CreateClass.css';

const CreateClass = () => {
  const [activeTab, setActiveTab] = useState('create'); // Başlangıç tabı

  // Form verileri
  const [name, setName] = useState('');
  const [courseId, setCourseId] = useState('');
  const [startDate, setStartDate] = useState('');
  const [classes, setClasses] = useState([]);
  const [selectedClass, setSelectedClass] = useState('');
  const [lessonTime, setLessonTime] = useState('');
  const [scheduleStartDate, setScheduleStartDate] = useState('');

  // Popup state
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');
  const [popupType, setPopupType] = useState('success'); // 'success' veya 'error'

  useEffect(() => {
    fetch('https://backend.ubicraft.org/api/classes')
      .then(response => response.json())
      .then(data => setClasses(data))
      .catch(error => console.error('Error fetching classes:', error));
  }, []);

  const handleCreateClass = () => {
    const newClass = { name, course_id: courseId, start_date: startDate };

    fetch('https://backend.ubicraft.org/api/classes', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newClass),
    })
      .then(response => response.json())
      .then(data => {
        setClasses([...classes, data]);
        // Başarılı mesajı göster
        setPopupMessage('Sınıf başarıyla oluşturuldu.');
        setPopupType('success');
        setShowPopup(true);
        // Formu sıfırla
        setName('');
        setCourseId('');
        setStartDate('');
      })
      .catch(error => {
        console.error('Error creating class:', error);
        setPopupMessage('Sınıf oluşturulurken bir hata oluştu.');
        setPopupType('error');
        setShowPopup(true);
      });
  };

  const handleScheduleLessons = () => {
    if (!selectedClass) {
      setPopupMessage('Lütfen bir sınıf seçin.');
      setPopupType('error');
      setShowPopup(true);
      return;
    }

    const scheduleData = { class_time: lessonTime, start_date: scheduleStartDate };

    fetch(`https://backend.ubicraft.org/api/classes/${selectedClass}/schedule`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(scheduleData),
    })
      .then(response => response.text())
      .then(text => {
        setPopupMessage('Dersler başarıyla planlandı.');
        setPopupType('success');
        setShowPopup(true);
        // Formu sıfırla
        setLessonTime('');
        setScheduleStartDate('');
      })
      .catch(error => {
        console.error('Error scheduling lessons:', error);
        setPopupMessage('Dersler planlanırken bir hata oluştu.');
        setPopupType('error');
        setShowPopup(true);
      });
  };

  const handleDeleteClass = () => {
    if (!selectedClass) {
      setPopupMessage('Lütfen bir sınıf seçin.');
      setPopupType('error');
      setShowPopup(true);
      return;
    }

    fetch(`https://backend.ubicraft.org/api/classes/${selectedClass}`, {
      method: 'DELETE',
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to delete class');
        }
        setClasses(classes.filter(cls => cls.id !== selectedClass));
        setSelectedClass('');
        setPopupMessage('Sınıf başarıyla silindi.');
        setPopupType('success');
        setShowPopup(true);
      })
      .catch(error => {
        console.error('Error deleting class:', error);
        setPopupMessage('Sınıf silinirken bir hata oluştu.');
        setPopupType('error');
        setShowPopup(true);
      });
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <div className="create-class">
      <h2>Sınıf Yönetimi</h2>
      <div className="tabs">
        <button className={activeTab === 'create' ? 'active' : ''} onClick={() => setActiveTab('create')}>
          Sınıf Oluşturma
        </button>
        <button className={activeTab === 'schedule' ? 'active' : ''} onClick={() => setActiveTab('schedule')}>
          Dersleri Planla
        </button>
        <button className={activeTab === 'delete' ? 'active' : ''} onClick={() => setActiveTab('delete')}>
          Sınıfı Sil
        </button>
      </div>

      <div className="tab-content">
        {activeTab === 'create' && (
          <div className="tab-panel">
            <h3>Sınıf Oluşturma</h3>
            <div className="form-group">
              <label>Sınıf İsmi</label>
              <input
                type="text"
                placeholder="Sınıf İsmi"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Kurs ID</label>
              <input
                type="number"
                placeholder="Kurs ID"
                value={courseId}
                onChange={(e) => setCourseId(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Başlangıç Tarihi</label>
              <input
                type="date"
                placeholder="Başlangıç Tarihi"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </div>
            <button className="btn-primary" onClick={handleCreateClass}>
              Sınıf Oluştur
            </button>
          </div>
        )}

        {activeTab === 'schedule' && (
          <div className="tab-panel">
            <h3>Dersleri Planla</h3>
            <div className="form-group">
              <label>Sınıf Seç</label>
              <select
                value={selectedClass}
                onChange={(e) => setSelectedClass(e.target.value)}
              >
                <option value="">Sınıf Seç</option>
                {classes.map((cls) => (
                  <option key={cls.id} value={cls.id}>
                    {cls.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label>Ders Saati</label>
              <input
                type="time"
                placeholder="Ders Saati"
                value={lessonTime}
                onChange={(e) => setLessonTime(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Planlama Başlangıç Tarihi</label>
              <input
                type="date"
                placeholder="Planlama Başlangıç Tarihi"
                value={scheduleStartDate}
                onChange={(e) => setScheduleStartDate(e.target.value)}
              />
            </div>
            <button className="btn-primary" onClick={handleScheduleLessons}>
              Dersleri Planla
            </button>
          </div>
        )}

        {activeTab === 'delete' && (
          <div className="tab-panel">
            <h3>Sınıfı Sil</h3>
            <div className="form-group">
              <label>Silinecek Sınıfı Seç</label>
              <select
                value={selectedClass}
                onChange={(e) => setSelectedClass(e.target.value)}
              >
                <option value="">Sınıf Seç</option>
                {classes.map((cls) => (
                  <option key={cls.id} value={cls.id}>
                    {cls.name}
                  </option>
                ))}
              </select>
            </div>
            <button
              className="btn-danger"
              onClick={handleDeleteClass}
              disabled={!selectedClass}
            >
              Seçili Sınıfı Sil
            </button>
          </div>
        )}
      </div>

      {/* Popup Bileşeni */}
      {showPopup && (
        <div className="popup">
          <div className={`popup-content ${popupType}`}>
            <span className="close-button" onClick={closePopup}>&times;</span>
            <p>{popupMessage}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateClass;
