import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { 
  Copy, 
  Check, 
  Send, 
  User, 
  ChevronDown, 
  ChevronUp,
  Sun,
  Moon,
  Layout
} from 'lucide-react';
import Sidebar from './activities';
import './UbiAI.css';
import './SelectedActivity.css';

const TypewriterEffect = ({ text, onComplete, delay = 20 }) => {
  const [displayedText, setDisplayedText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (!text) return;
    
    if (currentIndex < text.length) {
      const timer = setTimeout(() => {
        setDisplayedText(prev => prev + text[currentIndex]);
        setCurrentIndex(currentIndex + 1);
      }, delay);

      return () => clearTimeout(timer);
    } else if (onComplete) {
      onComplete();
    }
  }, [currentIndex, text, onComplete, delay]);

  return <span className="ubiAI-typewriter-text">{displayedText}</span>;
};

const StepCard = ({ step, index }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(step.code);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    } catch (err) {
      console.error('Kopyalama hatası:', err);
    }
  };

  return (
    <div className="ubiAI-step-card">
      <button 
        className="ubiAI-step-header"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <div className="ubiAI-step-title">
          <span className="ubiAI-step-number">{index + 1}</span>
          <h3>{step.step_title}</h3>
        </div>
        {isExpanded ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
      </button>
      
      {isExpanded && (
        <div className="ubiAI-step-content">
          <p className="ubiAI-step-description">{step.step_description}</p>
          {step.code && (
            <div className="ubiAI-code-container">
              <div className="ubiAI-code-header">
                <span>Python</span>
                <button 
                  className="ubiAI-copy-button"
                  onClick={handleCopy}
                >
                  {isCopied ? (
                    <>
                      <Check size={16} />
                      <span>Kopyalandı</span>
                    </>
                  ) : (
                    <>
                      <Copy size={16} />
                      <span>Kopyala</span>
                    </>
                  )}
                </button>
              </div>
              <pre className="ubiAI-code-block">
                <code>{step.code}</code>
              </pre>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const ActivityResponse = ({ content }) => {
  const [isCopied, setIsCopied] = useState(false);
  const [isTypingComplete, setIsTypingComplete] = useState(false);
  let parsedContent;

  try {
    parsedContent = typeof content === 'string' ? JSON.parse(content) : content;
  } catch (error) {
    console.error('JSON ayrıştırma hatası:', error);
    return (
      <div className="ubiAI-error-message">
        Yanıt işlenirken bir hata oluştu.
      </div>
    );
  }

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(parsedContent.full_code);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    } catch (err) {
      console.error('Kopyalama hatası:', err);
    }
  };

  return (
    <div className="ubiAI-activity-response">
      <div className="ubiAI-activity-header">
        <div className="ubiAI-activity-title">
          <TypewriterEffect 
            text={parsedContent.title}
            onComplete={() => {}}
            delay={30}
          />
          <div className="ubiAI-activity-description">
            <TypewriterEffect 
              text={parsedContent.description}
              onComplete={() => setIsTypingComplete(true)}
              delay={20}
            />
          </div>
        </div>
      </div>

      {isTypingComplete && (
        <>
          <div className="ubiAI-steps-section">
            <h3>Adımlar</h3>
            <div className="ubiAI-steps-list">
              {parsedContent.steps.map((step, index) => (
                <StepCard key={index} step={step} index={index} />
              ))}
            </div>
          </div>

          <div className="ubiAI-full-code-section">
            <div className="ubiAI-full-code-header">
              <h3>Tam Kod</h3>
              <button 
                className="ubiAI-copy-button"
                onClick={handleCopy}
              >
                {isCopied ? (
                  <>
                    <Check size={16} />
                    <span>Kopyalandı</span>
                  </>
                ) : (
                  <>
                    <Copy size={16} />
                    <span>Kopyala</span>
                  </>
                )}
              </button>
            </div>
            <div className="ubiAI-code-container">
              <pre className="ubiAI-code-block">
                <code>{parsedContent.full_code}</code>
              </pre>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const MessageBubble = ({ message, isUser }) => {
  return (
    <div className={`ubiAI-message-bubble ${isUser ? 'ubiAI-user-message' : 'ubiAI-assistant-message'}`}>
      <div className="ubiAI-message-header">
        <div className="ubiAI-user-info">
          {isUser ? (
            <>
              <div className="ubiAI-user-avatar">
                <User size={16} />
              </div>
              <span className="ubiAI-user-name">Sen</span>
            </>
          ) : (
            <>
              <div className="ubiAI-assistant-avatar">UBI</div>
              <span className="ubiAI-assistant-name">Python Asistanı</span>
            </>
          )}
        </div>
      </div>

      <div className="ubiAI-message-content">
        {isUser ? (
          <p className="ubiAI-message-text">{message.text}</p>
        ) : (
          <ActivityResponse content={message.text} />
        )}
      </div>
    </div>
  );
};

const QuickSuggestion = ({ text, onClick }) => (
  <button className="ubiAI-quick-suggestion" onClick={onClick}>
    {text}
  </button>
);

const SelectedActivity = ({ activity, onRemove }) => {
  return (
    <div className="ubiAI-selected-activity">
      <div className="ubiAI-selected-activity-header">
        <div className="ubiAI-selected-activity-info">
          <Layout size={16} />
          <span>{activity.activity_name} etkinliği eklendi</span>
        </div>
        <button 
          className="ubiAI-remove-button"
          onClick={onRemove}
          aria-label="Etkinliği kaldır"
        >
          Kaldır
        </button>
      </div>
    </div>
  );
};

const UbiAI = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [messages, setMessages] = useState([]);
  const [prompt, setPrompt] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [hasMessages, setHasMessages] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState(null);

  useEffect(() => {
    setHasMessages(messages.length > 0);
  }, [messages]);

  useEffect(() => {
    document.body.className = isDarkMode ? 'theme-dark' : 'theme-light';
  }, [isDarkMode]);

  const suggestions = [
    'Döngüler için örnek',
    'Liste işlemleri örneği',
    'Fonksiyon örnekleri',
    'Sınıf yapısı örneği',
    'Dosya işlemleri',
    'Hata yakalama'
  ];

  const handleSubmit = async () => {
    if (!prompt.trim() || isLoading) return;

    const userMessage = {
      id: Date.now(),
      text: prompt,
      isUser: true,
    };

    setMessages(prev => [...prev, userMessage]);
    setIsLoading(true);
    setPrompt('');

    try {
      const response = await axios.post('https://backend.ubicraft.org/api/generate-activity', {
        prompt: prompt,
        code_snippets: selectedActivity ? selectedActivity.code_snippets : null,
      });

      const assistantMessage = {
        id: Date.now() + 1,
        text: response.data.result,
        isUser: false,
      };

      setMessages(prev => [...prev, assistantMessage]);
      setSelectedActivity(null);
    } catch (error) {
      console.error('API hatası:', error);
      
      const errorMessage = {
        id: Date.now() + 1,
        text: JSON.stringify({
          title: "Hata Oluştu",
          description: "Üzgünüm, bir hata oluştu. Lütfen tekrar deneyin.",
          steps: [],
          full_code: ""
        }),
        isUser: false
      };

      setMessages(prev => [...prev, errorMessage]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setPrompt(suggestion);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit();
    }
  };

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
  };

  return (
    <div className={`ubiAI-app-container ${isDarkMode ? 'theme-dark' : 'theme-light'}`}>
      <button 
        className="ubiAI-theme-switcher"
        onClick={toggleTheme}
        aria-label="Toggle theme"
      >
        {isDarkMode ? <Sun size={20} /> : <Moon size={20} />}
      </button>

      <Sidebar 
        isOpen={isSidebarOpen}
        onToggle={() => setIsSidebarOpen(!isSidebarOpen)}
        onActivitySelect={(activity) => {
          setSelectedActivity(activity);
        }}
      />

      <div className={`ubiAI-main-content ${isSidebarOpen ? 'with-sidebar' : ''}`}>
        <div className="ubiAI-chat-container">
          <div className="ubiAI-content-wrapper">
            <section className="ubiAI-messages-section">
              {messages.map(message => (
                <MessageBubble
                  key={message.id}
                  message={message}
                  isUser={message.isUser}
                />
              ))}
            </section>

            <header className={`ubiAI-greeting-section ${hasMessages ? 'ubiAI-with-messages' : ''}`}>
              <TypewriterEffect 
                text="Ben Ubi, nasıl yardımcı olabilirim?" 
                delay={50}
              />
            </header>

            <footer className="ubiAI-input-footer">
              <div className="ubiAI-input-wrapper">
                {selectedActivity && (
                  <SelectedActivity 
                    activity={selectedActivity}
                    onRemove={() => setSelectedActivity(null)}
                  />
                )}
                <div className="ubiAI-input-section">
                  <textarea
                    className="ubiAI-chat-input"
                    placeholder="Python etkinliği üretmem için bir şeyler yaz..."
                    value={prompt}
                    onChange={e => setPrompt(e.target.value)}
                    onKeyDown={handleKeyPress}
                    disabled={isLoading}
                  />
                  <button
                    className={`ubiAI-submit-button ${isLoading ? 'ubiAI-loading' : ''}`}
                    onClick={handleSubmit}
                    disabled={isLoading || !prompt.trim()}
                  >
                    {isLoading ? (
                      <div className="ubiAI-loading-spinner" />
                    ) : (
                      <Send size={20} />
                    )}
                  </button>
                </div>

                <div className="ubiAI-quick-actions">
                  {suggestions.map((suggestion, index) => (
                    <QuickSuggestion
                      key={index}
                      text={suggestion}
                      onClick={() => handleSuggestionClick(suggestion)}
                    />
                  ))}
                </div>

                <div className="ubiAI-disclaimer">
                  Python etkinlik üreteci hata yapabilir. Önemli bilgileri kontrol edin.
                </div>
              </div>
            </footer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UbiAI;
