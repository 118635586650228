import React from "react";
import Footer from "../../components/Landing/Footer";
import PythonP from "../../components/PythonProSinavlar/PythonProSinav1";


export default function Pricingpage() {
  return (
    <>

      <PythonP />

    </>
  );
}
