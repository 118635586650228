import React from 'react';
import './ConsentPopup.css';

const ConsentPopup = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="consent-popup-overlay">
      <div className="consent-popup-content">
        <div className="consent-popup-header">
          <h2>UBICRAFT AÇIK RIZA VE AYDINLATMA METNİ</h2>
          <button className="consent-close-button" onClick={onClose}>
            X
          </button>
        </div>
        <div className="consent-popup-body">
          <p>
            Bu metin, Ubicraft platformu tarafından kişisel verilerin işlenmesi
            ve korunması hakkında bilgilendirme amaçlı hazırlanmıştır. Kişisel
            verileriniz, 6698 sayılı Kişisel Verilerin Korunması Kanunu
            ("KVKK") ve ilgili mevzuata uygun olarak işlenecektir.
          </p>
          <h3>1. VERİ SORUMLUSUNUN KİMLİĞİ</h3>
          <p>
            Ubicraft olarak, kullanıcılarımızın kişisel verilerinin korunmasına büyük önem veriyoruz. Bu aydınlatma metni, 6698 sayılı Kişisel Verilerin Korunması Kanunu (“KVKK”) gereğince, veri sorumlusu sıfatıyla Ubicraft’ın kişisel verilerin işlenmesi, saklanması, ve paylaşılması süreçleri hakkında sizleri bilgilendirmek amacıyla hazırlanmıştır.
          </p>
          <h3>2. İŞLENEN KİŞİSEL VERİLER</h3>
          <p>
            Ubicraft platformuna kayıt olduğunuzda ve hizmetlerimizden faydalandığınızda aşağıdaki kişisel verileriniz işlenmektedir:
          </p>
          <ul>
            <li>Kimlik Bilgileri: İsim, soyisim</li>
            <li>İletişim Bilgileri: E-posta adresi, telefon numarası, adres, vb.</li>
            <li>Eğitim Bilgileri: Öğrencinin eğitim geçmişi, öğrenim durumu, vb.</li>
            <li>Kullanım Bilgileri: Ubicraft platformundaki kullanıcı etkinlikleriniz, ders katılımı, ilerleme durumu, vb.</li>
          </ul>
          <h3>3. KİŞİSEL VERİLERİN İŞLENME AMAÇLARI</h3>
          <p>
            Kişisel verileriniz, aşağıdaki amaçlar doğrultusunda işlenmektedir:
          </p>
          <ul>
            <li>Platform hizmetlerinin sunulması ve sürdürülebilmesi,</li>
            <li>Öğrencilerin ilerlemelerinin takip edilmesi ve eğitim içeriklerinin sunulması,</li>
            <li>Ödeme işlemlerinin gerçekleştirilmesi ve faturalandırma süreçlerinin yürütülmesi,</li>
            <li>Müşteri destek hizmetlerinin sağlanması,</li>
            <li>Platform kullanımına dair analizlerin yapılması ve kullanıcı deneyiminin geliştirilmesi,</li>
            <li>Ubicraft tarafından düzenlenen etkinlikler, kampanyalar ve hizmetlerden haberdar edilmeniz,</li>
            <li>Yasal yükümlülüklerin yerine getirilmesi ve ilgili mevzuattan doğan hak ve sorumlulukların korunması.</li>
          </ul>
          <h3>4. KİŞİSEL VERİLERİN AKTARILMASI</h3>
          <p>
            Kişisel verileriniz, KVKK ve ilgili diğer mevzuat uyarınca, aşağıdaki kişi ve kuruluşlarla paylaşılabilir:
          </p>
          <ul>
            <li>Hukuken yetkili kamu kurum ve kuruluşları (mahkemeler, düzenleyici denetleyici kurumlar vb.),</li>
            <li>Ubicraft’ın yurt içi ve yurt dışındaki iş ortakları ve hizmet sağlayıcıları (barındırma hizmetleri, ödeme işlemcileri vb.),</li>
            <li>Ubicraft’ın iştirakleri, bağlı kuruluşları ve iş ortakları.</li>
          </ul>
          <h3>5. KİŞİSEL VERİLERİN TOPLANMA YÖNTEMİ VE HUKUKİ SEBEBİ</h3>
          <p>
            Kişisel verileriniz, Ubicraft platformu üzerinden elektronik ortamda, başvurular, kayıt formları, platform içi etkinlikler ve çerezler gibi çeşitli kanallarla toplanmaktadır. Kişisel verilerinizin işlenmesindeki hukuki sebepler şunlardır:
          </p>
          <ul>
            <li>Bir sözleşmenin kurulması veya ifası için gerekli olması,</li>
            <li>Ubicraft’ın yasal yükümlülüklerini yerine getirmesi,</li>
            <li>Kişisel verilerinizin işlenmesine yönelik açık rızanızın bulunması.</li>
          </ul>
          <h3>6. KİŞİSEL VERİLERİN SAKLANMA SÜRESİ</h3>
          <p>
            Kişisel verileriniz, işleme amacı ortadan kalktığında veya ilgili mevzuatta öngörülen süreler sonunda silinir, yok edilir veya anonim hale getirilir. Platform kullanımınıza dair bilgiler, platformdan ayrıldıktan sonra en fazla bir yıl boyunca saklanır. Yasal zorunluluklar çerçevesinde, verilerin daha uzun süre saklanması gerekebilecektir.
          </p>
          <h3>7. KVKK KAPSAMINDA HAKLARINIZ</h3>
          <p>
            Kişisel verilerinizle ilgili olarak, KVKK’nın 11. maddesi kapsamında sahip olduğunuz haklar şunlardır:
          </p>
          <ul>
            <li>Kişisel verilerinizin işlenip işlenmediğini öğrenme,</li>
            <li>İşlenmişse buna ilişkin bilgi talep etme,</li>
            <li>İşlenme amacını ve amaca uygun kullanılıp kullanılmadığını öğrenme,</li>
            <li>Yurt içinde veya yurt dışında kişisel verilerinizin aktarıldığı üçüncü kişileri bilme,</li>
            <li>Eksik veya yanlış işlenmişse düzeltilmesini isteme,</li>
            <li>İşlenme şartlarının ortadan kalkması halinde kişisel verilerinizin silinmesini veya yok edilmesini isteme,</li>
            <li>Düzeltme, silme veya yok edilme işlemlerinin, kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,</li>
            <li>İşlenen verilerin otomatik sistemler vasıtasıyla analiz edilmesi suretiyle aleyhinize bir sonucun ortaya çıkmasına itiraz etme,</li>
            <li>Kişisel verilerinizin kanuna aykırı işlenmesi sebebiyle zarara uğramanız hâlinde zararın giderilmesini talep etme.</li>
          </ul>
          <h3>8. AÇIK RIZA</h3>
          <p>
            Ubicraft platformuna kayıt olarak ve hizmetlerimizi kullanarak, yukarıda belirtilen amaçlarla kişisel verilerinizin işlenmesine açık rızanızı vermiş oluyorsunuz. Dilediğiniz zaman açık rızanızı geri çekme hakkınız bulunmaktadır. Bu durumda, kişisel verilerinizin işlenmesine devam edilmesi gereken yasal bir zorunluluk olmadıkça, verilerinizin işlenmesi durdurulacaktır.
          </p>
          <h3>9. İLETİŞİM BİLGİLERİ</h3>
          <p>
            Kişisel verilerinizle ilgili her türlü talep, soru veya başvurularınız için aşağıdaki iletişim bilgileri üzerinden bizimle iletişime geçebilirsiniz:
          </p>
          <p>
            <strong>Ubicraft E-posta:</strong> info@ubicraft.com<br />
            <strong>Adres:</strong> Bülbüzade Mah. 136019. Sok. No:20 İç Kapı No:17 Şahinbey/Gaziantep<br />
            <strong>Telefon:</strong> +90 (505) 713 17 27
          </p>
        </div>
      </div>
    </div>
  );
};

export default ConsentPopup;
