import React from 'react';
import { CheckCircle2, XCircle, Trophy, BarChart3, ArrowRight } from 'lucide-react';
import './QuizResult.css';

const QuizResultPopup = ({ 
  score, 
  total, 
  correctAnswers, 
  wrongAnswers, 
  emptyAnswers, 
  submitStatus,
  onClose,
  onRetry,
  onNavigateToResults
}) => {
  const percentage = Math.round((score / 100) * 100);
  
  return (
    <div className="quiz-popup-overlay">
      <div className="quiz-popup-container">
        <div className="quiz-popup-content">
          {/* Header */}
          <div className="quiz-popup-header">
            {percentage >= 70 ? (
              <Trophy className="quiz-popup-icon success" />
            ) : (
              <BarChart3 className="quiz-popup-icon default" />
            )}
            <h3 className="quiz-popup-title">
              Sınav Tamamlandı!
            </h3>
          </div>

          {/* Score Circle */}
          <div className="quiz-popup-score-circle">
            <div className="quiz-popup-score-container">
              <svg className="quiz-popup-score-svg">
                <circle
                  className="quiz-popup-score-background"
                  r="56"
                  cx="64"
                  cy="64"
                />
                <circle
                  className={`quiz-popup-score-progress ${
                    percentage >= 70 ? 'success' : 'default'
                  }`}
                  r="56"
                  cx="64"
                  cy="64"
                  style={{
                    strokeDasharray: '352.8571428571429',
                    strokeDashoffset: 352.8571428571429 * (1 - percentage / 100),
                  }}
                />
              </svg>
              <div className="quiz-popup-score-text">
                {percentage}%
              </div>
            </div>
          </div>

          {/* Statistics */}
          <div className="quiz-popup-stats">
            <div className="quiz-popup-stat-item correct">
              <CheckCircle2 className="quiz-popup-stat-icon correct" />
              <div className="quiz-popup-stat-value correct">{correctAnswers}</div>
              <div className="quiz-popup-stat-label">Doğru</div>
            </div>
            <div className="quiz-popup-stat-item wrong">
              <XCircle className="quiz-popup-stat-icon wrong" />
              <div className="quiz-popup-stat-value wrong">{wrongAnswers}</div>
              <div className="quiz-popup-stat-label">Yanlış</div>
            </div>
            <div className="quiz-popup-stat-item empty">
              <div className="quiz-popup-stat-icon empty">-</div>
              <div className="quiz-popup-stat-value empty">{emptyAnswers}</div>
              <div className="quiz-popup-stat-label">Boş</div>
            </div>
          </div>

          {/* Status Message */}
          {submitStatus && (
            <div className={`quiz-popup-status ${
              submitStatus === 'success' ? 'success' : 'error'
            }`}>
              {submitStatus === 'success' 
                ? 'Sonuçlarınız başarıyla kaydedildi!'
                : submitStatus.replace('error: ', 'Hata: ')}
            </div>
          )}

          {/* Actions */}
          <div className="quiz-popup-actions">
            <button
              onClick={onNavigateToResults}
              className="quiz-popup-button primary"
            >
              Sonuçlar Sayfasına Git
              <ArrowRight className="quiz-popup-button-icon" />
            </button>
            <button
              onClick={onRetry}
              className="quiz-popup-button secondary"
            >
              Tekrar Dene
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuizResultPopup;