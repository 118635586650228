// src/components/CourseTabs.jsx

import React, { useState } from 'react';
import { 
  BookOpen, 
  Target, 
  Users, 
  Award, 
  HelpCircle,
  Code,
  Brain,
  Cpu,
  Gamepad,
  Monitor,
  Boxes,
  GraduationCap,
  Building,
  BadgeCheck,
  BookOpenCheck,
  ChevronDown
} from 'lucide-react';
import './PythonPro2.css';

// FeatureCard bileşenini güncelliyoruz
const FeatureCard = ({ icon: Icon, number, title, description }) => (
  <div className="feature-card">
    <div className="icon-title-wrapper">
      <div className="icon-container2">
        {Icon ? <Icon className="feature-icon" /> : (
          <span className="feature-number">{number}</span>
        )}
      </div>
      <div className="text-container">
        <h3 className="feature-title">{title}</h3>
        <p className="feature-description">{description}</p>
      </div>
    </div>
  </div>
);

// AccordionItem bileşeni (değişiklik yok)
const AccordionItem = ({ question, answer, isOpen, onClick }) => (
  <div className="accordion-item">
    <button
      className={`accordion-header ${isOpen ? 'active' : ''}`}
      onClick={onClick}
    >
      <span className="accordion-question">{question}</span>
      <ChevronDown className={`accordion-icon ${isOpen ? 'rotate' : ''}`} />
    </button>
    <div className={`accordion-content ${isOpen ? 'show' : ''}`}>
      <div className="accordion-answer">{answer}</div>
    </div>
  </div>
);

// TabContent bileşeni
const TabContent = ({ content }) => (
  <div className="tab-content-container">
    <div className="tab-header">
      <div className="tab-header-icon">
        <content.icon className="tab-icon" />
      </div>
      <div className="tab-header-text">
        <h2 className="tab-title">{content.title}</h2>
        <p className="tab-description">{content.description}</p>
      </div>
    </div>
    {content.type === 'accordion' ? (
      <div className="accordion-container">
        {content.questions.map((item, index) => (
          <AccordionItem
            key={index}
            question={item.question}
            answer={item.answer}
            isOpen={item.isOpen}
            onClick={item.toggleOpen}
          />
        ))}
      </div>
    ) : (
      <div className="features-grid">
        {content.features.map((feature, index) => (
          <FeatureCard 
            key={index} 
            icon={feature.icon} 
            number={feature.number} 
            title={feature.title} 
            description={feature.description} 
          />
        ))}
      </div>
    )}
  </div>
);

// CourseTabs bileşeni
const CourseTabs = () => {
  const [activeTab, setActiveTab] = useState('genel');
  const [openQuestions, setOpenQuestions] = useState(new Set());

  const toggleQuestion = (index) => {
    setOpenQuestions(prev => {
      const newSet = new Set(prev);
      if (newSet.has(index)) {
        newSet.delete(index);
      } else {
        newSet.add(index);
      }
      return newSet;
    });
  };

  const tabContents = {
    genel: {
      icon: BookOpen,
      title: "Genel Bakış",
      description: "Yapay zeka ile modern teknoloji dünyasının kapılarını kapsamlı bir eğitim ile aralayın!",
      features: [
        {
          icon: Gamepad, // Daha uygun ikonlar seçildi
          title: "Web Geliştirme",
          description: "Geliştirdiğiniz yapay zeka uygulamalarını tüm dünyayla paylaşabileceğiniz websiteleri oluşturun!"
        },
        {
          icon: Brain, // Yapay Zeka için uygun ikon
          title: "Yapay Zeka",
          description: "Görüntü işleme, ses işleme, doğal dil işleme kavramlarıyla tanışın. İleri düzey uygulamalar geliştirin!"
        },
        {
          icon: Monitor, // Temel Syntax için uygun ikon
          title: "İleri Düzey Algoritmalar",
          description: "Python diline ve ileri düzey algoritmalara tam anlamıyla hakim olun! Bu sayede sadece Python bilmekle kalmaz diğer programlama dillerine de adapte olabilirsiniz!"
        }
      ]
    },
    egitim: {
      icon: Code,
      title: "Eğitim İçeriği",
      description: "Temelden zirveye adım adım ilerleyen müfredatımızla tanışın",
      features: [
        {
          number: 1,
          title: "Modül 1",
          description: "Doğal Dil İşleme Projeleri"
        },
        {
          number: 2,
          title: "Modül 2",
          description: "Görüntü İşleme Projeleri"
        },
        {
          number: 3,
          title: "Modül 3",
          description: "Ses İşleme Projeleri"
        },
        {
          number: 4,
          title: "Modül 4",
          description: "Görüntü - Metin Birleştirme"
        },
        {
          number: 5,
          title: "Modül 5",
          description: "Gelişmiş Entegrasyon"
        },
        {
          number: 6,
          title: "Modül 6",
          description: "Mezuniyet Modülü"
        }
      ]
    },
    kazanimlar: {
      icon: Target,
      title: "Kazanımlar",
      description: "Program sonunda elde edeceğiniz yetkinlikler ile kendinizi tüm dünyaya kanıtlayın.",
      features: [
        {
          icon: Code,
          title: "Python Geliştiricisi",
          description: "İleri düzey programlama ve algoritmalar"
        },
        {
          icon: BadgeCheck,
          title: "Profesyonel Yazılımcı",
          description: "Profesyonel Yazılımcıların kullandığı github platformu yeteneğinizi kanıtlayın!"
        },
        {
          icon: Users,
          title: "Yapay Zeka Geliştiricisi",
          description: "Yapay zeka uygulamalarınızı tüm dünyayla paylaşın!"
        }
      ]
    },
    kimler: {
      icon: Users,
      title: "Kimler Katılabilir",
      description: "Teknoloji tutkunu herkes bu programa katılabilir",
      features: [
        {
          icon: Building,
          title: "Ortaokul Öğrencileri",
          description: "İlgi alanına yönelmek isteyen, erkenden yeteneğini açığa çıkarmak isteyen ortaokul öğrencileri"
        },
        {
          icon: Code,
          title: "Lise Öğrencileri",
          description: "Hayallerindeki mühendislik bölümlerine erkenden adapte olmak isteyen lise öğrencileri"
        }
      ]
    },
    sertifika: {
      icon: Award,
      title: "Sertifika",
      description: "Sahip olduğunuzun becerileri, puanlarınızla ve sınav notlarınızla kanıtlayan Türkiye çapında geçerli Sertifika",
      features: [
        {
          icon: Award,
          title: "Dijital Rozet",
          description: "Python'a ve yapay zekaya hakim olduğunuzu kanıtlayan adınızı içeren size özel bir dijital rozet"
        },
        {
          icon: Target,
          title: "Yetkinlik Onayı",
          description: "Teknik yetkinlik değerlendirmesi"
        },
        {
          icon: Users,
          title: "Referans",
          description: "Eğitmen referans mektubu"
        }
      ]
    },
    sss: {
      icon: HelpCircle,
      title: "Sıkça Sorulan Sorular",
      description: "Program hakkında merak edilen sorular ve cevapları",
      type: 'accordion',
      questions: [
        {
          question: "Eğitim süresi ne kadardır?",
          answer: "Eğitim programımız toplam 24 hafta sürmektedir. Her hafta 1.5 saat canlı ders yapılmaktadır. Bunun yanında haftalık ödevler ve projeler için ortalama 30 - 60 dakika saatlik ek çalışma süresi önerilmektedir.",
          isOpen: openQuestions.has(0),
          toggleOpen: () => toggleQuestion(0)
        },
        {
          question: "Programa katılmak için ön koşullar nelerdir?",
          answer: "Programa katılmak için önceden programlama deneyimine sahip olmanız gereklidir.",
          isOpen: openQuestions.has(1),
          toggleOpen: () => toggleQuestion(1)
        },
        {
          question: "Sertifika programın sonunda veriliyor mu?",
          answer: "Evet, programı başarıyla tamamlayan tüm katılımcılara ulusal çapta geçerli bir sertifika verilmektedir. Sertifika almak için derslere minimum %80 katılım ve mezuniyet projesini tamamlamak gerekmektedir.",
          isOpen: openQuestions.has(2),
          toggleOpen: () => toggleQuestion(2)
        },
        {
          question: "Eğitim ücretinde taksit seçeneği var mı?",
          answer: "Evet, eğitim ücretini 12 aya kadar taksitlendirme imkanımız bulunmaktadır. Ayrıca erken kayıt ve öğrenci indirimleri de mevcuttur. Detaylı bilgi için bizimle iletişime geçebilirsiniz.",
          isOpen: openQuestions.has(3),
          toggleOpen: () => toggleQuestion(3)
        },
        {
          question: "Dersler canlı mı yapılıyor?",
          answer: "Evet, tüm derslerimiz canlı ve interaktif olarak yapılmaktadır. Dersler Zoom üzerinden gerçekleştirilmekte ve kayıt altına alınmaktadır. Kaçırdığınız dersleri daha sonra kayıtlardan izleyebilir ya da her modül için öğretmeninizle bir adet telafi dersi gerçekleştirebilirsiniz.",
          isOpen: openQuestions.has(4),
          toggleOpen: () => toggleQuestion(4)
        }
      ]
    }
  };

  return (
    <div className="course-container">
      <div className="tabs-wrapper">
        {Object.entries(tabContents).map(([id, content]) => (
          <button
            key={id}
            className={`tab-button ${activeTab === id ? 'active' : ''}`}
            onClick={() => setActiveTab(id)}
          >
            <content.icon className="tab-button-icon" />
            {content.title}
          </button>
        ))}
      </div>
      <div className="tab-content">
        <TabContent content={tabContents[activeTab]} />
      </div>
    </div>
  );
};

export default CourseTabs;
