import React from 'react';
import logo from './White.png'; // Görseli import ettik
import '@fortawesome/fontawesome-free/css/all.min.css';


const footerStyle = `
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

  .footer {
    font-family: 'Poppins', sans-serif;
    background-color: #301934;
    color: #E0E1DD;
    padding: 4rem 1rem;
  }

  .footer-container {
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
  }

  .footer-section {
    display: flex;
    flex-direction: column;
  }

  .footer-logo {
    width: 180px;
    margin-bottom: 1.5rem;
  }

  .footer-heading {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 1rem;
    color: #E0E1DD;
  }

  .footer-text, .footer-list-item, .footer-link {
    font-size: 0.95rem;
    line-height: 1.6;
  }

  .footer-list {
    list-style-type: none;
    padding: 0;
  }

  .footer-list-item {
    margin-bottom: 0.75rem;
  }

  .footer-link {
    color: #E0E1DD;
    text-decoration: none;
    transition: color 0.3s ease;
  }

  .footer-link:hover {
    color: #84A9AC;
  }

  .footer-social-icons {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
  }

  .footer-social-icon {
    color: #E0E1DD;
    font-size: 1.5rem;
    transition: color 0.3s ease;
  }

  .footer-social-icon:hover {
    color: #84A9AC;
  }

  .footer-bottom {
    text-align: center;
    margin-top: 3rem;
    border-top: 1px solid #1B263B;
    padding-top: 1.5rem;
    font-size: 0.9rem;
    color: #A5A6A9;
  }

  /* Responsive Tasarım */
  @media (max-width: 768px) {
    .footer {
      padding: 2rem 1rem;
    }
    .footer-logo {
      margin-bottom: 1rem;
    }
    .footer-bottom {
      margin-top: 2rem;
      padding-top: 1rem;
    }
  }
`;

const Footer = () => {
  return (
    <>
      <style>{footerStyle}</style>
      <footer className="footer">
        <div className="footer-container">
          {/* Logo ve Açıklama */}
          <div className="footer-section">
            <img src={logo} alt="Ubicraft Logo" className="footer-logo" />
            <p className="footer-text">
              8-17 yaş arası çocuklar için hazırlanmış çevrimiçi kodlama kursları sunuyoruz. Geleceğin teknoloji liderlerini yetiştiriyoruz.
            </p>
          </div>

          {/* Hızlı Bağlantılar */}
          <div className="footer-section">
            <h3 className="footer-heading">Hızlı Bağlantılar</h3>
            <ul className="footer-list">
              <li className="footer-list-item">
                <a href="#" className="footer-link">Anasayfa</a>
              </li>
              <li className="footer-list-item">
                <a href="#" className="footer-link">Kurslar</a>
              </li>
              <li className="footer-list-item">
                <a href="#" className="footer-link">Hakkımızda</a>
              </li>
              <li className="footer-list-item">
                <a href="#" className="footer-link">Blog</a>
              </li>
              <li className="footer-list-item">
                <a href="#" className="footer-link">İletişim</a>
              </li>
            </ul>
          </div>

          {/* İletişim Bilgileri */}
          <div className="footer-section">
            <h3 className="footer-heading">İletişim</h3>
            <ul className="footer-list">
              <li className="footer-list-item">
                Bülbüzade Mah. 136019. Sok. No:20 İç Kapı No:17 Şahinbey/Gaziantep
              </li>
              <li className="footer-list-item">
                Email: <a href="mailto:info@ubicraft.com" className="footer-link">info@ubicraft.com</a>
              </li>
              <li className="footer-list-item">
                Telefon: <a href="tel:+902125555555" className="footer-link">+90 (505) 713 17 27</a>
              </li>
            </ul>
            <div className="footer-social-icons">
              <a href="#" className="footer-social-icon" aria-label="Twitter">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="#" className="footer-social-icon" aria-label="Facebook">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a href="#" className="footer-social-icon" aria-label="Instagram">
                <i className="fab fa-instagram"></i>
              </a>
              <a href="#" className="footer-social-icon" aria-label="LinkedIn">
                <i className="fab fa-linkedin-in"></i>
              </a>
            </div>
          </div>
        </div>

        {/* Alt Bilgi */}
        <div className="footer-bottom">
          &copy; {new Date().getFullYear()} Ubicraft. Tüm hakları saklıdır.
        </div>
      </footer>
    </>
  );
};

export default Footer;
