import React, { useState } from "react";
import { FaPlus, FaMinus } from "react-icons/fa"; // Yeni ikonlar
import "./FAQs.css";
import sssImage from './sss.png';

const FAQS = () => {
  const [content1, setcontent1] = useState(false);
  const [content2, setcontent2] = useState(false);
  const [content3, setcontent3] = useState(false);
  const [content4, setcontent4] = useState(false);
  const [content5, setcontent5] = useState(false);
  const [content6, setcontent6] = useState(false);
  const [content7, setcontent7] = useState(false);
  const [content8, setcontent8] = useState(false);
  const [content9, setcontent9] = useState(false);
  const [content10, setcontent10] = useState(false);
  const [content11, setcontent11] = useState(false);

  return (
    <div className="faq-container">
      <div className="faq-content-container">
        <div>
          <p className="faq-title">Sıkça Sorulan Sorular</p>
        </div>

        <div className="faq-card">
          <div className="faq-header">
            <p className="faq-question">
              Çocuğumun bilgisayar kullanma becerilerinden tam emin değilim.
              <br /> Acaba çocuğum kodlamaya uygun mu?
            </p>
            <button
              onClick={() => setcontent1(!content1)}
              className="faq-button"
            >
              {content1 ? <FaMinus /> : <FaPlus />}
            </button>
          </div>
          <div className={`faq-content ${content1 ? "show" : ""}`}>
            <p>
              Ücretsiz deneme dersinden önce çocuğunuzun bilgisayar kullanma
              becerilerinden emin olmak amacıyla bir test gönderiyoruz. Bu
              testte öğrencimiz eğer yeterli puanı alamazsa ücretsiz 1 ders
              bilgisayar kullanımı dersi ve bu yeteneklerini geliştirebilecek
              etkinlikler ve egzersizlerle çocuğunuzun kursa hazır olmasını
              sağlıyoruz.
            </p>
          </div>
        </div>

        <div className="faq-card">
          <div className="faq-header">
            <p className="faq-question">Ücretsiz deneme dersinde neler yapılıyor?</p>
            <button
              onClick={() => setcontent2(!content2)}
              className={`faq-button ${content2 ? "bg-orange-logo" : ""}`}
            >
              {content2 ? <FaMinus /> : <FaPlus />}
            </button>
          </div>
          <div className={`faq-content ${content2 ? "show" : ""}`}>
            <p>
              Bu derste öğretmeniniz, platformumuzu tanıtacak ve ardından
              çocuğunuzun ilgi ve yeteneklerini belirlemek için detaylı bir
              analiz yapacak. Okulumuz hakkında daha fazla bilgi edinip, 1
              saatlik gerçek bir ders deneyimi yaşayacaksınız.
            </p>
          </div>
        </div>

        <div className="faq-card">
          <div className="faq-header">
            <p className="faq-question">Ödeme planlarınız nelerdir?</p>
            <button
              onClick={() => setcontent3(!content3)}
              className={`faq-button ${content3 ? "bg-orange-logo" : ""}`}
            >
              {content3 ? <FaMinus /> : <FaPlus />}
            </button>
          </div>
          <div className={`faq-content ${content3 ? "show" : ""}`}>
            <p>
              Kredi kartına taksit ya da tek çekim, banka kartına tek çekim olarak işlemlerinizi gerçekleştirebilirsiniz. Her ailenin özel ihtiyaçları olduğunun farkındayız. Size özel fiyatlandırmaları çağrı merkezimizle ücretsiz deneme dersinden sonra danışmanınızla görüşebilirsiniz.

            </p>
          </div>
        </div>

        <div className="faq-card">
          <div className="faq-header">
            <p className="faq-question">Kurs deneyiminden memnun kalmazsak para iadesi garantisi var mı?</p>
            <button
              onClick={() => setcontent4(!content4)}
              className={`faq-button ${content4 ? "bg-orange-logo" : ""}`}
            >
              {content4 ? <FaMinus /> : <FaPlus />}
            </button>
          </div>
          <div className={`faq-content ${content4 ? "show" : ""}`}>
            <p>
              Öğretmenlerimizin deneyimine, kurs materyallerimize ve
              platformumuza kesinlikle güveniyoruz. Bu nedenle; İlk modül (4
              ders) içinde koşulsuz şartsız para iadesi seçeneği bulunmaktadır.
              Kursun 8. haftasından önce kurstan ayrılmayı talep ederseniz,
              öğrencimizin henüz görmediği derslerin tamamının ücretini iade
              ediyoruz.
            </p>
          </div>
        </div>

        <div className="faq-card">
          <div className="faq-header">
            <p className="faq-question">Kodlama derslerinde hangi programlama dilleri öğretiliyor?</p>
            <button
              onClick={() => setcontent5(!content5)}
              className={`faq-button ${content5 ? "bg-orange-logo" : ""}`}
            >
              {content5 ? <FaMinus /> : <FaPlus />}
            </button>
          </div>
          <div className={`faq-content ${content5 ? "show" : ""}`}>
            <p>
              Kodlama derslerimizde dünyada en çok kullanılan programlama dili olan "Python" öğretilmektedir. Öğrencilerin yaş ve
              seviyelerine göre ders içerikleri uyarlanır.
            </p>
          </div>
        </div>

        <div className="faq-card">
          <div className="faq-header">
            <p className="faq-question">Dersler nasıl bir ortamda yapılıyor?</p>
            <button
              onClick={() => setcontent6(!content6)}
              className={`faq-button ${content6 ? "bg-orange-logo" : ""}`}
            >
              {content6 ? <FaMinus /> : <FaPlus />}
            </button>
          </div>
          <div className={`faq-content ${content6 ? "show" : ""}`}>
            <p>
              Derslerimiz online ortamda gerçekleşmektedir. Canlı dersler
              öğretmenlerimiz tarafından interaktif şekilde yürütülmektedir.
              Ayrıca öğrencilerimiz için kaydedilmiş dersler ve ek materyaller
              de mevcuttur.
            </p>
          </div>
        </div>

        <div className="faq-card">
          <div className="faq-header">
            <p className="faq-question">Çocuğum dersi anlamazsa ne olur?</p>
            <button
              onClick={() => setcontent7(!content7)}
              className={`faq-button ${content7 ? "bg-orange-logo" : ""}`}
            >
              {content7 ? <FaMinus /> : <FaPlus />}
            </button>
          </div>
          <div className={`faq-content ${content7 ? "show" : ""}`}>
            <p>
              Çocuğunuz dersi anlamazsa, öğretmenlerimiz birebir destek
              sağlayacaktır. Ekstra çalışma materyalleri ve özel ders
              seçenekleri ile çocuğunuzun konuyu anlamasını sağlayacağız.
            </p>
          </div>
        </div>

        <div className="faq-card">
          <div className="faq-header">
            <p className="faq-question">Derslere devam zorunluluğu var mı?</p>
            <button
              onClick={() => setcontent8(!content8)}
              className={`faq-button ${content8 ? "bg-orange-logo" : ""}`}
            >
              {content8 ? <FaMinus /> : <FaPlus />}
            </button>
          </div>
          <div className={`faq-content ${content8 ? "show" : ""}`}>
            <p>
              Derslere devam zorunluluğu bulunmaktadır. Ancak, mazeretli
              durumlarda telafi dersleri ile öğrencinin eksikleri
              tamamlanmaktadır.
            </p>
          </div>
        </div>

        <div className="faq-card">
          <div className="faq-header">
            <p className="faq-question">Ders materyalleri nasıl sağlanıyor?</p>
            <button
              onClick={() => setcontent9(!content9)}
              className={`faq-button ${content9 ? "bg-orange-logo" : ""}`}
            >
              {content9 ? <FaMinus /> : <FaPlus />}
            </button>
          </div>
          <div className={`faq-content ${content9 ? "show" : ""}`}>
            <p>
              Ders materyalleri dijital ortamda sağlanmaktadır. Öğrencilerimize
              interaktif slaytlar, dokümanlar ve interaktif ödevler sunuyoruz.
              Ayrıca, ders videoları ve ek kaynaklar da platformumuzda
              mevcuttur.
            </p>
          </div>
        </div>

        <div className="faq-card">
          <div className="faq-header">
            <p className="faq-question">Ders saatleri nasıl belirleniyor?</p>
            <button
              onClick={() => setcontent10(!content10)}
              className={`faq-button ${content10 ? "bg-orange-logo" : ""}`}
            >
              {content10 ? <FaMinus /> : <FaPlus />}
            </button>
          </div>
          <div className={`faq-content ${content10 ? "show" : ""}`}>
            <p>
              Ders saatleri öğrencilerin ve öğretmenlerin müsaitlik durumuna
              göre belirlenmektedir. Esnek saatler sunarak, herkesin rahatça
              katılabileceği bir program oluşturuyoruz.
            </p>
          </div>
        </div>

        <div className="faq-card">
          <div className="faq-header">
            <p className="faq-question">Çocuğum başka kurslarla eş zamanlı olarak bu kursa katılabilir mi?</p>
            <button
              onClick={() => setcontent11(!content11)}
              className={`faq-button ${content11 ? "bg-orange-logo" : ""}`}
            >
              {content11 ? <FaMinus /> : <FaPlus />}
            </button>
          </div>
          <div className={`faq-content ${content11 ? "show" : ""}`}>
            <p>
              Çocuğunuzun diğer kurslarla eş zamanlı olarak bu kursa katılması
              mümkündür. Ancak, ders programının çakışmaması için önceden
              programın gözden geçirilmesi önemlidir.
            </p>
          </div>
        </div>

      </div>
    </div>
  );
};

export default FAQS;
