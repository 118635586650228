// src/customComponents/AttendanceCard.jsx

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './AttendanceCard.css'; // Ensure the CSS path is correct

const AttendanceCard = ({ Name, studentId, classId }) => {
  const [weeks, setWeeks] = useState(Array(32).fill('grey'));
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);

  // Fetch attendance data when studentId or classId changes
  useEffect(() => {
    const fetchAttendance = async () => {
      try {
        const response = await fetch(`https://backend.ubicraft.org/api/attendance/${studentId}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        const newWeeks = Array(32).fill('grey'); // Reset weeks to default

        data.forEach(({ week, status }) => {
          const color =
            status === 'Katıldı' ? 'green' :
            status === 'Katılmadı' ? 'red' : 'yellow';
          if (week >= 1 && week <= 32) {
            newWeeks[week - 1] = color;
          }
        });
        setWeeks(newWeeks);
      } catch (error) {
        console.error('Error fetching attendance:', error);
        setErrorMessage('Yoklama verileri alınırken bir hata oluştu.');
      }
    };

    fetchAttendance();
  }, [studentId, classId]);

  // Handle attendance status change
  const handleColorChange = async (index, color) => {
    setIsUpdating(true);
    setErrorMessage('');
    const previousColor = weeks[index];
    const updatedWeeks = [...weeks];
    updatedWeeks[index] = color;
    setWeeks(updatedWeeks);
    setSelectedIndex(null);

    try {
      const response = await fetch('https://backend.ubicraft.org/api/attendance', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          studentId: studentId,
          classId: classId,
          week: index + 1,
          status: color === 'green' ? 'Katıldı' :
                  color === 'red' ? 'Katılmadı' : 'İzinli'
        })
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      // Check the Content-Type header to determine how to parse the response
      const contentType = response.headers.get('Content-Type');
      let result;
      if (contentType && contentType.includes('application/json')) {
        result = await response.json();
      } else {
        result = await response.text();
      }

      console.log('Attendance updated:', result);
    } catch (error) {
      console.error('Error updating attendance:', error);
      // Revert to previous color
      setWeeks(prevWeeks => {
        const revertedWeeks = [...prevWeeks];
        revertedWeeks[index] = previousColor;
        return revertedWeeks;
      });
      setErrorMessage('Yoklama güncellenirken bir hata oluştu. Değişiklikler geri alındı.');
    } finally {
      setIsUpdating(false);
    }
  };

  // Toggle color options visibility
  const handleBoxClick = (index) => {
    setSelectedIndex(selectedIndex === index ? null : index);
  };

  // Organize weeks into rows for display
  const rows = [];
  for (let i = 0; i < weeks.length; i += 16) {
    rows.push(weeks.slice(i, i + 16));
  }

  return (
    <div className="attendance-card">
      <div className="name-label">
        {Name}
      </div>
      <div className="weeks-container">
        {rows.map((row, rowIndex) => (
          <div key={rowIndex}>
            <div className="module-row font-poppins">
              {Array.from({ length: 4 }, (_, modIndex) => (
                <div className="module-label font-poppins" key={modIndex}>
                  {`M${rowIndex * 4 + modIndex + 1}`}
                </div>
              ))}
            </div>
            <div className="week-row font-poppins">
              {row.map((color, index) => {
                const globalIndex = rowIndex * 16 + index;
                return (
                  <div className="week2-container font-poppins" key={globalIndex}>
                    <div
                      className={`week-box ${color}`}
                      onClick={() => handleBoxClick(globalIndex)}
                    >
                      <span className="week-number font-poppins">{globalIndex + 1}</span>
                    </div>
                    {selectedIndex === globalIndex && (
                      <div className="color-options font-poppins">
                        <button
                          className="option-button green"
                          onClick={() => handleColorChange(globalIndex, 'green')}
                        >
                          Katıldı
                        </button>
                        <button
                          className="option-button red"
                          onClick={() => handleColorChange(globalIndex, 'red')}
                        >
                          Katılmadı
                        </button>
                        <button
                          className="option-button yellow"
                          onClick={() => handleColorChange(globalIndex, 'yellow')}
                        >
                          İzinli
                        </button>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        ))}
      </div>
      {isUpdating && <div className="loading">Güncelleniyor...</div>}
      {errorMessage && <div className="error-message">{errorMessage}</div>}
    </div>
  );
};

// PropTypes for type checking
AttendanceCard.propTypes = {
  Name: PropTypes.string.isRequired,
  studentId: PropTypes.number.isRequired,
  classId: PropTypes.number.isRequired
};

export default AttendanceCard;
