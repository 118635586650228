import React, { useState } from "react";
import "./CoursesSection.css";
import { Link, useNavigate } from "react-router-dom";
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa';
import P1Icon from "./courseimages/p1.png";
import P2Icon from "./courseimages/p2.png";
import P3Icon from "./courseimages/p3.png";

const CoursesSection = () => {
  const [flippedCards, setFlippedCards] = useState([]);
  const navigate = useNavigate();

  const handleFlip = (index) => {
    if (flippedCards.includes(index)) {
      setFlippedCards(flippedCards.filter((i) => i !== index));
    } else {
      setFlippedCards([...flippedCards, index]);
    }
  };

  const handleNavigation = (path) => {
    // Önce sayfayı en üste kaydır
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    
    // Sonra yönlendirmeyi yap
    navigate(path);
  };

  const courses = [
    {
      ageRange: "8-17 YAŞ",
      title: "Python Start",
      description:
        "Python'ın gücüyle tanışın, temellerini öğrenin. Uzman öğretmenler eşliğinde temel seviye algoritmalar ve çizimler ile kodlama sanatını keşfedin!",
      duration: "8 Hafta",
      curriculum: [
        { week: "Modül 1", content: "Turtle ve Temel Syntax" },
        { week: "Modül 2", content: "Koşullu İfadeler ve Döngüler" },
        { week: "+", content: "Mezuniyet Projesi" }
      ],
      level: "Başlangıç",
      tags: ["Algoritma", "Kodlama", "Çizimler"],
      icon: P1Icon,
      path: "/python-start"
    },
    {
      ageRange: "8-17 YAŞ",
      title: "Python Plus",
      description:
        "Python deneyiminizi bir üst seviyeye taşıyın. Python'ı tam anlamıyla öğrenin. Orta seviye algoritmalar, 2D oyunlar, çizimler ile kodlama sanatına tam anlamıyla hakim olun!",
      duration: "32 Hafta",
      curriculum: [
        { week: "Modül 1", content: "Turtle ve Temel Syntax" },
        { week: "Modül 2", content: "Koşullu İfadeler ve Döngüler" },
        { week: "Modül 3", content: "Döngüler ve Algoritma" },
        { week: "Modül 4", content: "Listeler ve Sözlükler" },
        { week: "Modül 5", content: "Oyun Geliştirme M1" },
        { week: "Modül 6", content: "Oyun Geliştirme M2" },
        { week: "Modül 7", content: "Oyun Geliştirme M3" },
        { week: "Modül 8", content: "Mezuniyet Modülü" },
      ],
      level: "Başlangıç",
      tags: ["Algoritma", "Orta Seviye", "2D oyunlar", "Projeler"],
      icon: P2Icon,
      path: "/python-plus"
    },
    {
      ageRange: "11-17 YAŞ",
      title: "Python PRO",
      description:
        "Python deneyiminizi bir üst seviyeye taşıyın. Yapay zeka uygulamalarınız kendi geliştirdiğiniz websitesine taşıyın. Bunları CV'nize ekleyerek erkenden kariyer fırsatlarını keşfedin. Geleceğin yıldızları için idealdir!",
      duration: "24 Hafta",
      curriculum: [
        { week: "Modül 1", content: "Doğal Dil İşleme Projeleri" },
        { week: "Modül 2", content: "Görüntü İşleme Projeleri" },
        { week: "Modül 3", content: "Ses İşleme Projeleri" },
        { week: "Modül 4", content: "Görüntü - Metin Birleştirme" },
        { week: "Modül 5", content: "Gelişmiş Entegrasyon" },
        { week: "Modül 6", content: "Mezuniyet Modülü" },
      ],
      level: "İleri düzey",
      tags: ["Yapay Zeka", "Web Sitesi", "İleri Düzey","Prompt Eng."],
      icon: P3Icon,
      path: "/python-pro"
    },
  ];

  return (
    <div className="mt-8">
      <div className="flex justify-center items-center">
        <p className="courses-title">
          <span className="courses-title-highlight">Kurslarımız </span>
        </p>
      </div>

      <div className="flex justify-center items-center mt-4">
        <p className="text-center text-[#301934] font-poppins font-normal text-2xl">
          Yeni nesil kurslarımızı ücretsiz deneme dersi ile keşfedin!
        </p>
      </div>
      <div className="CoursesSectionParentCard flex gap-10 px-10 py-8 mt-8 mx-8 justify-start items-center overflow-x-auto">
        {courses.map((course, index) => (
          <div
            key={index}
            className={`CoursesSectionCard w-80 h-[650px] px-6 py-4 bg-orange-1 rounded-[20px] flex flex-col justify-between relative ${
              flippedCards.includes(index) ? "flipped" : ""
            }`}
          >
            <div className="card-front flex flex-col gap-6">
              <img src={course.icon} alt={`Icon ${index + 1}`} className="icon" />
              <div>
                <p className="font-poppins font-bold text-2xl text-[#301934]">
                  {course.title}
                </p>
                <p className="w-[22px] h-[2px] bg-orange-logo mt-1"></p>
              </div>
              <div className="flex flex-col justify-center items-center mt-8">
                <p className="font-poppins font-semibold text-3xl text-[#301934]">
                  {course.ageRange}
                </p>
                <p className="font-poppins font-normal text-xl text-[#747474]">
                  {course.duration}
                </p>
              </div>
              <div className="flex flex-col gap-4 mt-5">
                <div>
                  <p className="font-poppins font-semibold text-sm text-[#301934]">
                    Sizi neler bekliyor?
                  </p>
                </div>
                <div className="flex flex-col gap-2">
                  <p className="font-poppins font-normal text-[#301934] text-sm">
                    {course.description}
                  </p>
                </div>
              </div>
              <div className="flex flex-wrap gap-2 mt-5">
                {course.tags.map((tag, i) => (
                  <span
                    key={i}
                    className="bg-white text-[#301934] font-poppins font-medium rounded-full px-3 py-1 text-xs"
                  >
                    {tag}
                  </span>
                ))}
              </div>
              <div className="flex justify-center items-center mt-6">
                <button
                  onClick={() => handleFlip(index)}
                  className="view-curriculum-btn"
                >
                  Kurs müfredatını göster <FaArrowRight />
                </button>
              </div>
              <div className="flex rounded-lg mt-4 justify-center items-center">
                <button
                  onClick={() => handleNavigation(course.path)}
                  className="more-info-btn font-poppins font-semibold text-white bg-orange-logo hover:bg-orange-logo-dark transition-colors duration-300 rounded-xl py-2 px-4 w-full"
                >
                  Daha fazla bilgi alın
                </button>
              </div>
            </div>
            <div className="card-back flex flex-col justify-start items-start p-4">
              <p className="font-poppins font-bold text-2xl text-[#301934] mb-4">
                Müfredat
              </p>
              <div>
                {course.curriculum.map((item, i) => (
                  <div key={i} className="curriculum-item">
                    <span className="curriculum-week">{item.week}</span> {item.content}
                  </div>
                ))}
              </div>
              <div className="flex justify-center items-center mt-6">
                <button
                  onClick={() => handleFlip(index)}
                  className="hide-curriculum-btn"
                >
                  Kurs müfredatını gizle <FaArrowLeft />
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CoursesSection;