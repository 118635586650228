import React, { useState } from 'react';
import './TermsAndConditionsPopup.css';

const PrivacyPolicyPopup = ({ isOpen, onClose }) => {
  const [activeSection, setActiveSection] = useState('introduction');

  const sections = [
    { id: 'introduction', title: 'Giriş' },
    { id: 'collectedData', title: 'Toplanan Veriler' },
    { id: 'purposeOfData', title: 'Verilerin Toplanma ve İşlenme Amacı' },
    { id: 'dataProtection', title: 'Verilerin Korunması ve Saklanması' },
    { id: 'dataSharing', title: 'Kişisel Verilerin Üçüncü Kişilerle Paylaşılması' },
    { id: 'dataRights', title: 'Kişisel Verilerinize İlişkin Haklarınız' },
    { id: 'dataTransfer', title: 'Verilerin Yurtdışına Aktarılması' },
    { id: 'childProtection', title: 'Çocukların Korunması' },
    { id: 'securityMeasures', title: 'Veri Güvenliği Önlemleri' },
    { id: 'policyChanges', title: 'Politika Değişiklikleri' },
    { id: 'contactInfo', title: 'İletişim Bilgileri' },
  ];

  if (!isOpen) return null;

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <h2>Ubicraft Platformu Gizlilik Politikası</h2>
        <div className="popup-body">
          <div className="section-nav">
            {sections.map((section) => (
              <button
                key={section.id}
                className={activeSection === section.id ? 'active' : ''}
                onClick={() => setActiveSection(section.id)}
              >
                {section.title}
              </button>
            ))}
          </div>
          <div className="section-content">
            {activeSection === 'introduction' && (
              <div>
                <h3>Giriş</h3>
                <p>Ubicraft Platform olarak, kişisel verilerinizin gizliliğine ve güvenliğine büyük önem veriyoruz. Bu gizlilik politikası, 6698 sayılı Kişisel Verilerin Korunması Kanunu ("KVKK") uyarınca, kişisel verilerinizi nasıl topladığımızı, işlediğimizi ve koruduğumuzu açıklamaktadır. Platformumuzu kullanarak bu politikayı kabul etmiş sayılırsınız.</p>
              </div>
            )}
            {activeSection === 'collectedData' && (
              <div>
                <h3>Toplanan Veriler</h3>
                <p>Ubicraft Platformu'nda aşağıdaki kişisel veriler toplanmaktadır:</p>
                <ul>
                  <li>Öğrencinin Kişisel Verileri: Ad, soyad, doğum tarihi, kurs ilerleme durumu, ödev ve gelişim raporları.</li>
                  <li>Velinin Kişisel Verileri: Ad, soyad, telefon numarası, e-posta adresi, posta adresi.</li>
                  <li>Teknik Veriler: Kullanıcı IP adresleri, giriş ve çıkış tarihleri, platform üzerindeki etkileşimler.</li>
                </ul>
              </div>
            )}
            {activeSection === 'purposeOfData' && (
              <div>
                <h3>Verilerin Toplanma ve İşlenme Amacı</h3>
                <p>Toplanan kişisel veriler aşağıdaki amaçlarla işlenmektedir:</p>
                <ul>
                  <li>Eğitim Hizmetlerinin Sağlanması: Öğrencinin derslere erişimini sağlamak, ödev kontrolü ve yoklama sistemlerinin yürütülmesi.</li>
                  <li>İletişim Amaçları: Velilere bilgilendirme yapmak, gerektiğinde öğrenci hakkında bilgi paylaşmak.</li>
                  <li>Gelişim Takibi: Öğrencilerin eğitim performanslarını izlemek ve geliştirmek.</li>
                  <li>Platformun İyileştirilmesi: Kullanıcı deneyimini geliştirmek amacıyla platform kullanım verilerinin analiz edilmesi.</li>
                </ul>
              </div>
            )}
            {activeSection === 'dataProtection' && (
              <div>
                <h3>Verilerin Korunması ve Saklanması</h3>
                <p>Ubicraft Platformu, kişisel verilerinizi güvenli bir şekilde korumak için gerekli tüm teknik ve idari tedbirleri almaktadır. Bu kapsamda:</p>
                <ul>
                  <li>Şifrelenmiş Erişim: Kullanıcı hesaplarına erişim, şifrelenmiş verilerle sağlanır.</li>
                  <li>SSL Sertifikası: Platform, güvenli veri iletimi için SSL sertifikaları ile korunmaktadır.</li>
                  <li>Verilerin Saklanma Süresi: Öğrencilerin kursu tamamlamasını takiben, kişisel veriler 1 hafta içerisinde sistemden tamamen silinir.</li>
                  <li>Üçüncü Taraf Hizmetler: Eğitim hizmetlerinin sağlanması için Zoom, Trinket ve Google Colab gibi üçüncü taraf araçları kullanılmaktadır. Bu hizmetler tamamen ücretsiz sunulmakta olup, bu araçlarla paylaşılan veriler de gizlilik politikamıza uygun şekilde korunmaktadır.</li>
                </ul>
              </div>
            )}
            {activeSection === 'dataSharing' && (
              <div>
                <h3>Kişisel Verilerin Üçüncü Kişilerle Paylaşılması</h3>
                <p>Ubicraft, kişisel verilerinizi üçüncü kişilerle izinsiz paylaşmaz. Ancak, aşağıdaki durumlarda veriler üçüncü kişilerle paylaşılabilir:</p>
                <ul>
                  <li>Yasal Zorunluluklar: Mevzuat gereği veya yargı mercilerinin talebi üzerine kişisel verilerin paylaşılması gerekebilir.</li>
                  <li>Hizmet Sağlayıcıları: Teknik destek ve diğer hizmetlerin sağlanabilmesi amacıyla hizmet sağlayıcıları ile sınırlı ölçüde veri paylaşımı yapılabilir. Bu hizmet sağlayıcılar, kişisel verilerinizi yalnızca hizmetin gerektirdiği amaçlarla kullanabilir.</li>
                </ul>
              </div>
            )}
            {activeSection === 'dataRights' && (
              <div>
                <h3>Kişisel Verilerinize İlişkin Haklarınız</h3>
                <p>KVKK uyarınca, kişisel verilerinizle ilgili olarak aşağıdaki haklara sahipsiniz:</p>
                <ul>
                  <li>Bilgi Talebi: Kişisel verilerinizin işlenip işlenmediğini öğrenme.</li>
                  <li>Verilere Erişim: Kişisel verilerinize erişim talep etme.</li>
                  <li>Düzeltme Talebi: Kişisel verilerinizin eksik veya yanlış işlenmiş olması halinde düzeltme talep etme.</li>
                  <li>Silme Talebi: Kişisel verilerinizin işlenmesini gerektiren sebeplerin ortadan kalkması durumunda verilerinizin silinmesini talep etme.</li>
                  <li>İtiraz: Kişisel verilerinizin işlenmesine itiraz etme.</li>
                </ul>
                <p>Bu haklarınızı kullanmak için info@ubicraft.com adresine e-posta gönderebilir veya iletişim bilgilerimizi kullanarak bizimle iletişime geçebilirsiniz.</p>
              </div>
            )}
            {activeSection === 'dataTransfer' && (
              <div>
                <h3>Verilerin Yurtdışına Aktarılması</h3>
                <p>Ubicraft Platformu, kişisel verilerinizi yurtdışına aktarmaz. Ancak, Zoom, Google Colab ve Trinket gibi üçüncü taraf hizmet sağlayıcılar aracılığıyla bazı veriler yurtdışında saklanabilir. Bu hizmetler, güvenlik ve gizlilik politikalarımıza uygun olarak veri işlemlerini yürütmektedir.</p>
              </div>
            )}
            {activeSection === 'childProtection' && (
              <div>
                <h3>Çocukların Korunması</h3>
                <p>Platformumuzun kullanıcıları 8-17 yaş arası çocuklardan oluşmaktadır ve bu nedenle ebeveyn izni zorunludur. Çocukların kişisel verileri sadece eğitim hizmetlerinin sağlanması ve platform deneyiminin iyileştirilmesi amacıyla kullanılmaktadır.</p>
              </div>
            )}
            {activeSection === 'securityMeasures' && (
              <div>
                <h3>Veri Güvenliği Önlemleri</h3>
                <p>Kişisel verilerinizi korumak amacıyla şu önlemleri almaktayız:</p>
                <ul>
                  <li>Verileriniz, güvenli sunucularda ve şifrelenmiş olarak saklanmaktadır.</li>
                  <li>Yetkisiz erişim, veri kaybı, veri sızıntısı gibi risklere karşı gelişmiş güvenlik duvarları ve diğer güvenlik sistemleri kullanılmaktadır.</li>
                </ul>
              </div>
            )}
            {activeSection === 'policyChanges' && (
              <div>
                <h3>Politika Değişiklikleri</h3>
                <p>Ubicraft, bu gizlilik politikasını gerektiğinde güncelleme hakkını saklı tutar. Güncellemeler platform üzerinden duyurulacak ve kullanıcıların onayına sunulacaktır.</p>
              </div>
            )}
            {activeSection === 'contactInfo' && (
              <div>
                <h3>İletişim Bilgileri</h3>
                <p>Herhangi bir sorunuz veya kişisel verilerinizle ilgili talepleriniz için bizimle aşağıdaki iletişim bilgileri üzerinden irtibata geçebilirsiniz:</p>
                <p>Adres: Bülbüzade Mah. 136019. Sok. No:20 İç Kapı No:17 Şahinbey/Gaziantep</p>
                <p>E-posta: info@ubicraft.com</p>
                <p>Telefon: +90 (505) 713 17 27</p>
              </div>
            )}
          </div>
        </div>
        <button className="close-button" onClick={onClose}>Kapat</button>
      </div>
    </div>
  );
};

export default PrivacyPolicyPopup;