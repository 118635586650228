import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import './Notification.css';
import TrackCard from './TrackCard';
import Participation from './Participation';
import Score from './Score';

const Notification = () => {
  const { userId } = useParams();
  const [lessons, setLessons] = useState([]);
  const [expandedWeek, setExpandedWeek] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const hasFetched = useRef(false);

  useEffect(() => {
    if (hasFetched.current) {
      return;
    }

    const fetchLessons = async () => {
      setLoading(true);
      setError(null);
      console.log('Fetching lessons for user:', userId);

      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`https://backend.ubicraft.org/api/student/${userId}/lessons`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();

          const lessonsByWeek = data.reduce((acc, lesson) => {
            const weekNumber = lesson.week_number;

            if (!acc[weekNumber]) {
              acc[weekNumber] = {
                week_number: weekNumber,
                lesson_name: lesson.lesson_name,
                details: lesson.details,
                slide: lesson.slide,
                is_accessible: lesson.is_accessible,
                activities: [],
              };
            }

            acc[weekNumber].activities.push({
              activity_id: lesson.activity_id,
              activity_name: lesson.activity_name,
              replit_url: lesson.replit_url,
            });
            return acc;
          }, {});

          setLessons(Object.values(lessonsByWeek));
          hasFetched.current = true;
        } else {
          console.error('Dersler alınamadı:', response.statusText);
          setError('Dersler alınamadı: ' + response.statusText);
        }
      } catch (error) {
        console.error('Dersleri alırken hata oluştu:', error);
        setError('Dersleri alırken hata oluştu: ' + error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchLessons();
  }, [userId]);

  const toggleWeek = (weekNumber) => {
    setExpandedWeek((prev) => (prev === weekNumber ? null : weekNumber));
  };

  const renderModule = (moduleLessons, moduleNumber) => (
    <div key={moduleNumber} className="notification-module-border">
      <div className="notification-module-header">
        <h2 className="notification-module-title">Modül {moduleNumber}</h2>
      </div>
      {moduleLessons.map((lesson, index) => (
        <div
          key={index}
          className={`notification-lesson-card ${lesson.is_accessible ? 'accessible' : 'locked'}`}
        >
          <div className="notification-lesson-header" onClick={() => lesson.is_accessible && toggleWeek(lesson.week_number)}>
            <div className="notification-week-number-circle">{lesson.week_number}</div>
            <div className="notification-lesson-info">
              <h3 className='notification-lesson-name'>{lesson.lesson_name}</h3>
              <p>{lesson.details}</p>
            </div>
            <div className="notification-locked-section">
              {!lesson.is_accessible && (
                <p className="notification-locked-message">Bu ders henüz erişilebilir değil.</p>
              )}
              {lesson.slide && (
                <a
                  className="notification-common-button notification-slide-button"
                  href={lesson.slide}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={(e) => e.stopPropagation()}
                >
                  Kaynak
                </a>
              )}
            </div>
            <div className={`notification-arrow ${expandedWeek === lesson.week_number ? 'expanded' : ''}`}>
              <span>&#9660;</span>
            </div>
          </div>
          {expandedWeek === lesson.week_number && (
            <>
              <hr />
              <div
                className={`notification-activities-container ${expandedWeek === lesson.week_number ? 'show' : ''}`}
                style={{ maxHeight: expandedWeek === lesson.week_number ? '1000px' : '0px' }}
              >
                {lesson.activities.map((activity, idx) => (
                  <div key={activity.activity_id} className="notification-activity-item">
                    <div className="notification-activity-number-circle">{idx + 1}</div>
                    <span>{activity.activity_name}</span>
                    <a className="notification-common-button notification-activity-link" href={activity.replit_url} target="_blank" rel="noopener noreferrer">
                      Etkinliğe Git
                    </a>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      ))}
    </div>
  );

  const groupedLessons = lessons.reduce((acc, lesson, index) => {
    const moduleIndex = Math.floor(index / 4);
    if (!acc[moduleIndex]) {
      acc[moduleIndex] = [];
    }
    acc[moduleIndex].push(lesson);
    return acc;
  }, []);

  return (
    <div className="notification-container">
      <TrackCard />
      <div className="notification-lessons-container">
        {loading && <p>Yükleniyor...</p>}
        {error && <p className="notification-error-message">{error}</p>}
        {!loading && !error && groupedLessons.map((moduleLessons, moduleNumber) =>
          renderModule(moduleLessons, moduleNumber + 1)
        )}
      </div>
    </div>
  );
};

export default Notification;
