import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import './Login.css';
import logo from './logo192.png';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (!username || !password) {
      setError('Kullanıcı adı ve şifre gereklidir');
      return;
    }

    if (!recaptchaToken) {
      setError('Lütfen reCAPTCHA doğrulamasını tamamlayın');
      return;
    }

    const response = await fetch('https://backend.ubicraft.org/auth/login', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ username, password, recaptchaToken })
    });

    if (response.ok) {
      const data = await response.json();
      localStorage.setItem('token', data.token);
      const user = parseJwt(data.token);
      if (user.role === 'student') {
        navigate(`/student/${user.userId}`);
      } else if (user.role === 'teacher') {
        navigate(`/dashboard/${user.userId}`);
      } else if (user.role === 'admin') {
        navigate('/admin');
      }
    } else {
      setError('Giriş Başarısız');
    }
  };

  const handleRecaptchaChange = (token) => {
    setRecaptchaToken(token);
  };

  const closeErrorPopup = () => {
    setError('');
  };

  return (
    <div className="login-page">
      <div className="login-container">
        <img src={logo} alt="Logo" className="login-logo" />
        {error && (
          <div className="login-error-popup">
            <span>{error}</span>
            <button onClick={closeErrorPopup} className="login-error-close">X</button>
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Kullanıcı Adı"
            className="login-input"
          />
          <div className="login-password-container">
            <input
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Şifre"
              className="login-input"
            />
            <button type="button" className="login-toggle-password" onClick={() => setShowPassword(!showPassword)}>
              {showPassword ? '🙈' : '👁️'}
            </button>
          </div>
          <ReCAPTCHA
            sitekey="6Lc1fC0qAAAAALC3yDXNEYW7X1FKwckTXQ3x4XxP"
            onChange={handleRecaptchaChange}
          />
          <div className="login-remember-me">
            <label>
              <input type="checkbox" className="login-checkbox" />
              Beni Hatırla
            </label>
            <a href="#" className="login-forgot-password">Şifremi Unuttum?</a>
          </div>
          <button type="submit" className="login-button">Giriş Yap</button>
        </form>
      </div>
    </div>
  );
};

function parseJwt(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

export default Login;
