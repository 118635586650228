import React, { useState, useEffect } from "react";
import { FaCircle, FaTimes, FaSquare } from "react-icons/fa";
import { FaCode, FaLightbulb, FaRobot } from "react-icons/fa";
import './Creativity.css';  // CSS dosyasını import ediyoruz

const Creativity = () => {
  const [activeCard, setActiveCard] = useState(0);
  const cards = [
    {
      icon: <FaCode size={120} />,
      title: "Kodlama Macerası",
      description: "Çocuklar için tasarlanmış, interaktif bir platform olan Ubicraft kodlama becerilerini öğrenme ve yaratıcılığı geliştirme imkanı sunuyor."
    },
    {
      icon: <FaLightbulb size={120} />,
      title: "Hayal Gücü",
      description: "Sadece ders anında değil her zaman yanınızdayız! 7/24 erişiminize açık olan Ubicraft platform ile çocuklar diledikleri an hayal güçlerini gerçek projelere dönüştürebilirler."
    },
    {
      icon: <FaRobot size={120} />,
      title: "Yapay Zeka",
      description: "Her çocuk özeldir. Öğretmen geri bildirimlerinin yanı sıra yapay zeka destekli Ubicraft platform sayesinde öğrenciye özel çözümler üretiyoruz."
    }
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveCard((prevActiveCard) => (prevActiveCard + 1) % cards.length);
    }, 5000);
    return () => clearInterval(interval);
  }, [cards.length]);

  return (
    <div className="creativity-container">
      {/* Arka plandaki simgeler */}
      <div className="icon-pattern">
        <FaCircle className="pattern-icon" />
        <FaTimes className="pattern-icon" />
        <FaSquare className="pattern-icon" />
        <FaCircle className="pattern-icon" />
        <FaTimes className="pattern-icon" />
        <FaSquare className="pattern-icon" />
        <FaCircle className="pattern-icon" />
        <FaTimes className="pattern-icon" />
      </div>

      <div className="header">
        <h2 className="section-title">Neden <span className="ubicraft-highlight">Ubicraft?</span></h2>
        <p className="section-subtitle">Çocuklar için tasarlanmış, interaktif bir platform olan Ubicraft kodlama becerilerini öğrenme ve yaratıcılığı geliştirme imkanı sunuyor. Geleceğin liderlerini şimdiden şekillendirmeye başlayın!</p>
      </div>

      <div className="creativity-cards-container">
        {cards.map((card, index) => (
          <div
            key={index}
            className={`creativity-card ${index === activeCard ? 'active' : ''}`}
          >
            <div className="card-content">
              <div className="ubicon">{card.icon}</div>
              <div className="text-content">
                <h3 className="card-title">{card.title}</h3>
                <p className="card-description">{card.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Creativity;
