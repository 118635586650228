// src/components/EducationContent.jsx

import React, { useState } from 'react';
import './PythonStart3.css'; // Animasyon CSS'ini içe aktarın

const ContentCard = ({ image, title, description, color, isActive, onClick }) => (
  <div 
    className={`relative overflow-hidden w-full sm:w-72 rounded-2xl transition-all duration-300 cursor-pointer ${
      isActive ? 'shadow-xl scale-105' : 'hover:shadow-lg hover:scale-102'
    }`}
    onClick={onClick}
  >
    <div className="aspect-[4/3] relative">
      <div 
        className="absolute inset-0"
        style={{ backgroundColor: color }}
      >
        <img 
          src={image} 
          alt={title}
          className="w-full h-full object-cover animated-float" // Animasyon sınıfını ekleyin
        />
      </div>
    </div>
    
    <div className="bg-white p-6 shadow-sm">
      <h3 className="text-lg font-semibold text-gray-800">{title}</h3>
      {isActive && (
        <p className="mt-2 text-sm text-gray-600 leading-relaxed">
          {description}
        </p>
      )}
    </div>
  </div>
);

const EducationContent = () => {
  const [activeCard, setActiveCard] = useState(null);

  const contents = [
    {
      image: "/assets/Cizim.png",
      title: "Çizimler",
      description: "Turtle modülü ile sadece kodlar yardımıyla eşsiz dijital çizimler yaratın!",
      color: "#ffe054"
    },
    {
      image: "/assets/Syntax.png",
      title: "Syntax",
      description: "Python dilinin sözdizimine hakim olun! Bu sayede sadece Python bilmekle kalmaz diğer programlama dillerine de adapte olabilirsiniz!",
      color: "#78caf3"
    },
    {
      image: "/assets/AI.png",
      title: "Yapay Zeka",
      description: "Öğrenilen Python sözdizimi sayesinde bir sonraki yapay zeka kursumuzun temellerini atın!",
      color: "#58dd91"
    }
  ];

  return (
    <div className="py-12 px-4 max-w-7xl mx-auto">
      <h2 className="text-4xl font-bold text-center mb-12">Eğitimde Neler Var?</h2>
      
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 justify-items-center">
        {contents.map((content, index) => (
          <ContentCard
            key={index}
            {...content}
            isActive={activeCard === index}
            onClick={() => setActiveCard(activeCard === index ? null : index)}
          />
        ))}
      </div>
    </div>
  );
};

export default EducationContent;
