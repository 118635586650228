import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import "./ExamResults.css";

const ExamResults = () => {
  const { userId } = useParams(); // Get userId from URL
  const navigate = useNavigate(); // For navigation
  const [examResults, setExamResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const scoreRanges = [
    { min: 0, max: 20, color: '#ff4d4f', label: 'Başarısız' },
    { min: 21, max: 45, color: '#fa8c16', label: 'Geçer' },
    { min: 46, max: 65, color: '#faad14', label: 'Orta' },
    { min: 66, max: 85, color: '#52c41a', label: 'İyi' },
    { min: 86, max: 100, color: '#1890ff', label: 'Çok İyi' },
  ];

  useEffect(() => {
    fetchExamResults();
  }, [userId]); // Fetch again if userId changes

  const fetchExamResults = async () => {
    setLoading(true);
    setError(null);
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`https://backend.ubicraft.org/api/student/${userId}/exams`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      
      if (!response.ok) {
        if (response.status === 404) {
          setExamResults([]);
          setLoading(false);
          return;
        }
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const data = await response.json();
      
      // Check if data exists
      if (!data) {
        throw new Error('Veri bulunamadı');
      }

      // Set the state with data
      setExamResults(data);
    } catch (error) {
      console.error('Sınav sonuçları yüklenirken hata:', error);
      setError('Sınav sonuçları yüklenirken bir hata oluştu. Lütfen daha sonra tekrar deneyin.');
    } finally {
      setLoading(false);
    }
  };

  const calculateGrade = (score) => {
    const range = scoreRanges.find(range => score >= range.min && score <= range.max);
    return range ? range.label : 'Belirsiz';
  };

  const getGradeColor = (score) => {
    const range = scoreRanges.find(range => score >= range.min && score <= range.max);
    return range ? range.color : '#1890ff';
  };

  const handleStartExam = (examId) => {
    // Create the redirect URL with examId
    const redirectUrl = `/student/${userId}/exam/${examId}`;
    navigate(redirectUrl);
  };

  if (error) {
    return (
      <div className="exams-container">
        <div className="error-message">
          <p>{error}</p>
          <button 
            className="retry-button"
            onClick={fetchExamResults}
          >
            Tekrar Dene
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="exams-container">
      <h2 className="exams-title">Sınav Sonuçları</h2>

      {loading ? (
        <div className="loading-skeleton">
          {[...Array(5)].map((_, index) => (
            <div key={index} className="skeleton-item"></div>
          ))}
        </div>
      ) : (
        <>
          {examResults.length === 0 ? (
            <div className="no-results">
              <p>Henüz sınav sonucu bulunmamaktadır.</p>
            </div>
          ) : (
            <>
              <div className="exams-list">
                {examResults.map((exam) => (
                  <div key={exam.exam_id} className="exam-item">
                    <div className="exam-info">
                      <h3 className="exam-name">{exam.exam_name || 'İsimsiz Sınav'}</h3>
                      <span className="exam-date">
                        {exam.exam_date ? 
                          new Date(exam.exam_date).toLocaleDateString('tr-TR') : 
                          'Tarih belirtilmemiş'
                        }
                      </span>
                    </div>
                    <div className="exam-details">
                      <div className="exam-stats">
                        <div className="stat-item">
                          <span className="stat-label">Doğru</span>
                          <span className="stat-value correct">{exam.correct_answers || 0}</span>
                        </div>
                        <div className="stat-item">
                          <span className="stat-label">Yanlış</span>
                          <span className="stat-value wrong">{exam.wrong_answers || 0}</span>
                        </div>
                        <div className="stat-item">
                          <span className="stat-label">Boş</span>
                          <span className="stat-value empty">{exam.empty_answers || 0}</span>
                        </div>
                      </div>
                      <div 
                        className="grade-circle"
                        style={{
                          backgroundColor: getGradeColor(exam.score || 0)
                        }}
                      >
                        <span className="grade-value">{exam.score || 0}</span>
                        <span className="grade-label">{calculateGrade(exam.score || 0)}</span>
                      </div>
                      {/* Start Exam Button */}
                      <button 
                        className="start-exam-button"
                        onClick={() => handleStartExam(exam.exam_id)}
                      >
                        Sınava Başla
                      </button>
                    </div>
                  </div>
                ))}
              </div>
              <div className="legend">
                <h3 className="legend-title">Not Aralıkları</h3>
                <div className="legend-items">
                  {scoreRanges.map((range, index) => (
                    <div key={index} className="legend-item">
                      <div className="legend-color" style={{ backgroundColor: range.color }}></div>
                      <span className="legend-label">{range.label} ({range.min}-{range.max})</span>
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default ExamResults;
