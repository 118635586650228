import React from 'react';
import { FaUserPlus, FaCalendarCheck, FaCreditCard, FaLaptopCode, FaCertificate } from 'react-icons/fa';
import './Regestration.css';

const Step = ({ icon: Icon, title, description, isFirst, isLast }) => (
  <div className={`step ${isFirst ? 'first-step' : ''} ${isLast ? 'last-step' : ''}`}>
    <div className="step-connector">
      {!isFirst && <div className="connector-line-top"></div>}
      <div className="connector-dot"></div>
      {!isLast && <div className="connector-line-bottom"></div>}
    </div>
    <div className="step-content">
      <div className="step-icon">
        <Icon />
      </div>
      <div className="step-text">
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
    </div>
  </div>
);

const ContinuousCourseEnrollmentProcess = () => {
  const steps = [
    {
      icon: FaUserPlus,
      title: "Ücretsiz Deneme Dersi",
      description: "Çocuğunuzun seviyesini ve ilgi alanlarını belirlemek için bir ön değerlendirme yapılır."
    },
    {
      icon: FaCalendarCheck,
      title: "Uygun Kurs Seçimi",
      description: "Öğrenci beceri seviyesine ve tecrübesine bağlı olarak en uygun kursa yönlendirilir."
    },
    {
      icon: FaCreditCard,
      title: "Ödeme ve Kayıt",
      description: "Uygun kurs paketini seçin ve ödemeyi tamamlayarak kaydınızı oluşturun. Kurs sürecinde her zaman yanınızda olacak müşteri temsilcimizşe size uygun kurs planı yapılır."
    },
    {
      icon: FaLaptopCode,
      title: "Kurs Başlangıcı",
      description: "Belirlenen tarihte dersler başlar. Tüm materyallere ve kaynaklara erişim sağlanır."
    },
    {
      icon: FaCertificate,
      title: "Sertifika",
      description: "Kurs tamamlandığında, çocuğunuz eğer beceri testimizi başarıyla tamamlarsa kurs sertifikasını almaya hak kazanır."
    }
  ];

  return (
    <div className="course-enrollment-container">
      <h2>Kayıt Prosedürü Nasıl İşler?</h2>
      <div className="enrollment-process">
        {steps.map((step, index) => (
          <Step 
            key={index} 
            {...step} 
            isFirst={index === 0}
            isLast={index === steps.length - 1} 
          />
        ))}
      </div>
      <div className="enrollment-info">
        <h3>Önemli Bilgiler:</h3>
        <ul>
          <li>Kurslarımız 8-17 yaş arası çocuklar için uygundur.</li>
          <li>Dersler haftada bir, 1.5 saat sürmektedir.</li>
          <li>Tüm dersler online olarak gerçekleştirilmektedir.</li>
          <li>İlk ay içerisinde kurs ücretinin tamamı iade edilebilir.</li>
          <li>Teknik destek ve danışmanlık hizmeti sürekli olarak sağlanmaktadır.</li>
        </ul>
      </div>
    </div>
  );
};

export default ContinuousCourseEnrollmentProcess;