import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./TrackCard.css";
import Proje from "../../Icons/Proje.png";
import Kurs from "../../Icons/Kurs.png";
import Ogrenci from "../../Icons/Ogrenci.png";
import Python from "../../Icons/Python.png";
import Dersler from "../../Icons/Dersler.png";

const TrackCard = () => {
  const { userId } = useParams();
  const [courses, setCourses] = useState([]);
  const [progress, setProgress] = useState({}); // Store progress for each course

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`https://backend.ubicraft.org/api/student/${userId}/courses`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        if (response.ok) {
          const data = await response.json();
          setCourses(data);
          
          // Calculate progress for each course
          data.forEach(async (course) => {
            const progressPercentage = await calculateProgress(course);
            setProgress(prev => ({ ...prev, [course.id]: progressPercentage })); // Store progress by course ID
          });
        } else {
          console.error('Failed to fetch courses:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching courses:', error);
      }
    };

    fetchCourses();
  }, [userId]);

  // Fetch student's completed weeks for each course
  const fetchCompletedWeeks = async (courseId) => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`https://backend.ubicraft.org/api/progress/${userId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (response.ok) {
        const data = await response.json();
        return data.completedWeeks.length;  // Completed weeks count
      } else {
        console.error('Failed to fetch completed weeks:', response.statusText);
        return 0;
      }
    } catch (error) {
      console.error('Error fetching completed weeks:', error);
      return 0;
    }
  };

// Calculate progress for a course based on completed weeks
const calculateProgress = async (course) => {
  const completedWeeks = await fetchCompletedWeeks(course.id);
  const progressPercentage = (completedWeeks / course.lessons_count) * 100; // Use course.total_weeks
  return progressPercentage;
};


  return (
    <div className="track-cards">
      {courses.map((course) => (
        <div className="track-card" key={course.id}>
          <div className="track-header">
            <span className="track-title">{course.course_name.toUpperCase()}</span>
            <h1>{course.course_name}</h1>
            <button className="continue-button">Kursa Devam Et</button>
          </div>
          <div className="track-info">
            <div className="track-meta">
              <div className="track-meta-item">
                <img src={Python} alt="Python" />
                <span>Python</span>
              </div>
              <div className="track-meta-item">
                <img src={Dersler} alt="Dersler" />
                <span>{course.lessons_count} Ders</span>
              </div>
              <div className="track-meta-item">
                <img src={Proje} alt="Proje" />
                <span>{course.projects_count} Proje</span>
              </div>
              <div className="track-meta-item">
                <img src={Kurs} alt="Kurs" />
                <span>{course.advanced_courses}</span>
              </div>
              <div className="track-meta-item">
                <img src={Ogrenci} alt="Öğrenci" />
                <span>{course.students_count} Öğrenci</span>
              </div>
            </div>
          </div>
          <div className="track-footer">
            <div className="track-progress">
              <span>{course.description}</span>
              <div className="progress-bar">
                {/* Display dynamic progress */}
                <div className="progress" style={{ width: `${progress[course.id] || 0}%` }}></div>
              </div>
            </div>
            <div className="bonus-material">
              <span>Bonus Proje {course.bonus_projects}/4</span>
              <div className="bonus-icons">
                {[...Array(4)].map((_, i) => (
                  <div key={i} className="bonus-icon">🌟</div>
                ))}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TrackCard;
