import React from 'react';
import { Clock, Users, Award, Star } from 'lucide-react';
import './PythonPro.css';

const AnimatedBadge = ({ children, className }) => (
  <div className={`pci-animated-badge ${className}`}>
    <div className="pci-badge-content">
      <div className="pci-animated-circle-1"></div>
      <div className="pci-animated-circle-2"></div>
      <div className="pci-animated-dot"></div>
      <span className="pci-badge-text">{children}</span>
    </div>
  </div>
);

const PythonCourseIntro = () => {
  return (
    <div className="pci-course-container">
      <div className="pci-wrapper">
        <div className="pci-course-card">
          <div className="pci-course-content">
            <div className="pci-content-left">
              <div className="pci-badges-container">
                <AnimatedBadge className="pci-badge-purple">
                  ONLİNE EĞİTİM
                </AnimatedBadge>
                <AnimatedBadge className="pci-badge-green">
                  TECRÜBELİ ÖĞRENCİLER İÇİN
                </AnimatedBadge>
              </div>
              
              <h1 className="pci-course-title">Python Pro Kursu</h1>
              
              <p className="pci-course-description">
                  Python Pro kursu daha önceden Ubicraft'tan Python Start kursunu almış öğrenciler için
                  idealdir. Yapay zeka kavramlarından veri bilimine, web geliştirme uygulamalarından ileri düzey
                  algoritmalara geniş bir yelpazeye sahip bir müfredat ile geleceğin kapılarını aralayacaksınız!
              </p>

              <div className="pci-students-info">
                <Users className="pci-icon" />
                <span>
                  Son <strong>12 </strong> kontenjan kaldı!
                </span>
              </div>

              <div className="pci-features-grid">
                <div className="pci-feature-card">
                  <Star className="pci-feature-icon pci-yellow" />
                  <div>
                    <div className="pci-feature-title">İleri Seviye</div>
                    <div className="pci-feature-subtitle">Pyton Start kursu tamamlanmış olmalı!</div>
                  </div>
                </div>
                <div className="pci-feature-card">
                  <Award className="pci-feature-icon pci-purple" />
                  <div>
                    <div className="pci-feature-title">Sertifika</div>
                    <div className="pci-feature-subtitle">Bitirme sertifikası</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="pci-pricing-container">
              <div className="pci-pricing-card">
                <div className="pci-pricing-header">
                  <h2>Kurs Detayları</h2>
                </div>
                <div className="pci-pricing-content">
                  <div className="pci-price-row">
                    <span>Eğitim Ücreti</span>
                    <span className="pci-price">6200 TL</span>
                  </div>
                  
                  <div className="pci-detail-row">
                    <span>Toplam Süre</span>
                    <div className="pci-time-info">
                      <Clock className="pci-icon" />
                      <span>36 Saat / 6 Ay</span>
                    </div>
                  </div>

                  <div className="pci-detail-row">
                    <span>Sertifika</span>
                    <span>Var</span>
                  </div>

                  {/* Hemen Başla Butonu */}
                  <a
                    href="https://form.jotform.com/243057905406961"
                    className="pci-primary-button"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Hemen Başla
                  </a>
                  
                  {/* Detaylı Bilgi Al Butonu */}
                  <a
                    href="https://api.whatsapp.com/send/?phone=905057131727&text&type=phone_number&app_absent=0"
                    className="pci-secondary-button"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Detaylı Bilgi Al
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PythonCourseIntro;
